export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CentsAmount: { input: any; output: any }
  ISO8601Date: { input: any; output: any }
  ISO8601DateTime: { input: any; output: any }
  JSON: { input: any; output: any }
  Upload: { input: any; output: any }
}

/** Autogenerated input type of Accept */
export type AcceptInput = {
  attendeeId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of Accept. */
export type AcceptPayload = {
  __typename?: 'AcceptPayload'
  attendee?: Maybe<Attendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of AcceptTicketRequest */
export type AcceptTicketRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of AcceptTicketRequest. */
export type AcceptTicketRequestPayload = {
  __typename?: 'AcceptTicketRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of ActivateTicket */
export type ActivateTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of ActivateTicket. */
export type ActivateTicketPayload = {
  __typename?: 'ActivateTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

export type ActsAsTaggableOn = Node & {
  __typename?: 'ActsAsTaggableOn'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  text: Scalars['String']['output']
}

/** Autogenerated input type of AddAttendeesByTicketIdentifier */
export type AddAttendeesByTicketIdentifierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  ticketIdentifiers: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of AddAttendeesByTicketIdentifier. */
export type AddAttendeesByTicketIdentifierPayload = {
  __typename?: 'AddAttendeesByTicketIdentifierPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  results?: Maybe<Array<AttendeeByTicketIdentifierResult>>
}

export type AgencyMatch = {
  __typename?: 'AgencyMatch'
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logo?: Maybe<ReviewsAsset>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type AgencyMatchPaginatedCollection = {
  __typename?: 'AgencyMatchPaginatedCollection'
  nodes: Array<AgencyMatch>
  pageInfo: OffsetPaginationInfo
}

export type AirtableAttachment = {
  __typename?: 'AirtableAttachment'
  filename: Scalars['String']['output']
  id: Scalars['ID']['output']
  url: Scalars['String']['output']
}

export type AirtableAttachmentAttributes = {
  creations?: InputMaybe<Array<Scalars['Upload']['input']>>
  deletions?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Autogenerated input type of Apply */
export type ApplyInput = {
  acceptedSpecialTerms?: InputMaybe<Scalars['Boolean']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventIds: Array<Scalars['ID']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of Apply. */
export type ApplyPayload = {
  __typename?: 'ApplyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

export type ArticleMatch = {
  __typename?: 'ArticleMatch'
  authors: Array<HygraphAuthor>
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  publishDateTime: Scalars['ISO8601DateTime']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ArticleMatchPaginatedCollection = {
  __typename?: 'ArticleMatchPaginatedCollection'
  nodes: Array<ArticleMatch>
  pageInfo: OffsetPaginationInfo
}

export type Attendee = Node & {
  __typename?: 'Attendee'
  aasmState: Scalars['String']['output']
  addedViaManage: Scalars['Boolean']['output']
  checkedInAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  event: PublishedEvent
  forcedAllocation?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  tagList?: Maybe<Array<Scalars['String']['output']>>
  ticket?: Maybe<Ticket>
}

export enum AttendeeAasmStateEnum {
  Accepted = 'accepted',
  Denied = 'denied',
  Requested = 'requested',
}

export type AttendeeByTicketIdentifierResult = {
  __typename?: 'AttendeeByTicketIdentifierResult'
  companyName?: Maybe<Scalars['String']['output']>
  errorMessage?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  ticketIdentifier: Scalars['String']['output']
}

/** The connection type for Attendee. */
export type AttendeeConnection = {
  __typename?: 'AttendeeConnection'
  acceptedCount: Scalars['Int']['output']
  acceptedSuccessorCount: Scalars['Int']['output']
  deniedCount: Scalars['Int']['output']
  /** A list of edges. */
  edges: Array<AttendeeEdge>
  /** A list of nodes. */
  nodes: Array<Attendee>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  requestedCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type AttendeeEdge = {
  __typename?: 'AttendeeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Attendee>
}

export enum AttendeeOrderAttributesEnum {
  AasmState = 'aasm_state',
  CheckedInAt = 'checked_in_at',
  CompanyName = 'company_name',
  CompanyPosition = 'company_position',
  CreatedAt = 'created_at',
  EventId = 'event_id',
  FinishedNotificationSentAt = 'finished_notification_sent_at',
  Id = 'id',
  Rating = 'rating',
  TicketId = 'ticket_id',
  UpdatedAt = 'updated_at',
}

export type BadgeTransaction = Node & {
  __typename?: 'BadgeTransaction'
  badgeTransactionItems: Array<BadgeTransactionItem>
  /** Company name */
  company: Scalars['String']['output']
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** External Badge ID from GET */
  externalBadgeId: Scalars['String']['output']
  /** External ID from GET */
  externalId: Scalars['String']['output']
  /** Transaction Type */
  getType: Scalars['String']['output']
  /** Sum of gifted credits */
  giftCreditsGross: Scalars['Float']['output']
  id: Scalars['ID']['output']
  /** Invoice PDF URL */
  invoicePdfUrl: Scalars['String']['output']
  /** Invoice Url landingpage. Use invoice_pdf_url for the pdf */
  invoiceUrl: Scalars['String']['output']
  /** Notification Send At */
  notificationSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Order the Receipt is part of */
  order?: Maybe<Order>
  /** JSON API response */
  payload: Scalars['String']['output']
  /** Real credit sum */
  realCreditsGross: Scalars['Float']['output']
  /** Receipt */
  receipt: Scalars['String']['output']
  /** Receipt Number */
  receiptNumber: Scalars['String']['output']
  /** Server Receive Date (from GET) */
  serverReceiveDate: Scalars['ISO8601DateTime']['output']
  /** Badge Session. Always 1 */
  sessionCounter: Scalars['Int']['output']
  /** If true, the transaction was skipped or taken out of the order */
  skip: Scalars['Boolean']['output']
  /** Status of the Badge Transaction. Only if true is the badge_transaction relevant */
  status: Scalars['String']['output']
  /** Total credit sum */
  totalCreditsGross: Scalars['Float']['output']
  /** Transaction Date */
  transactionDate: Scalars['ISO8601DateTime']['output']
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** The connection type for BadgeTransaction. */
export type BadgeTransactionConnection = {
  __typename?: 'BadgeTransactionConnection'
  /** A list of edges. */
  edges: Array<BadgeTransactionEdge>
  /** A list of nodes. */
  nodes: Array<BadgeTransaction>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type BadgeTransactionEdge = {
  __typename?: 'BadgeTransactionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<BadgeTransaction>
}

export type BadgeTransactionItem = Node & {
  __typename?: 'BadgeTransactionItem'
  /** BadgeTransaction */
  badgeTransaction: BadgeTransaction
  /** Count */
  count?: Maybe<Scalars['Int']['output']>
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Gift Credits Gross */
  giftCreditsGross?: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  /** Item Type */
  itemType?: Maybe<Scalars['String']['output']>
  /** Items Real Credits Gross */
  itemsRealCreditsGross?: Maybe<Scalars['Float']['output']>
  /** Name */
  name?: Maybe<Scalars['String']['output']>
  /** Real Credits Gross */
  realCreditsGross?: Maybe<Scalars['Float']['output']>
  /** Skip */
  skip: Scalars['Boolean']['output']
  /** Tax Rate */
  taxRate: Scalars['Float']['output']
  /** Tax Rate Name */
  taxRateName?: Maybe<Scalars['String']['output']>
  /** Total Credits Gross */
  totalCreditsGross: Scalars['Float']['output']
  /** Updated At */
  transactionDate: Scalars['ISO8601DateTime']['output']
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type BillingAddress = {
  __typename?: 'BillingAddress'
  PartnerCompany: PartnerCompany
  careOf?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  houseNumber?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
}

/** Attributes for creating or updating a billing_address */
export type BillingAddressAttributes = {
  /** C/O */
  careOf?: InputMaybe<Scalars['String']['input']>
  /** City */
  city?: InputMaybe<Scalars['String']['input']>
  /** Country Code */
  country?: InputMaybe<Scalars['String']['input']>
  /** House Number */
  houseNumber?: InputMaybe<Scalars['String']['input']>
  /** Name */
  name?: InputMaybe<Scalars['String']['input']>
  /** Postal Code */
  postalCode?: InputMaybe<Scalars['String']['input']>
  /** Street */
  street?: InputMaybe<Scalars['String']['input']>
}

export type Booth = Node & {
  __typename?: 'Booth'
  backCoverDesignUrl?: Maybe<Scalars['String']['output']>
  boost: Scalars['Int']['output']
  boothNumber?: Maybe<Scalars['String']['output']>
  counterBrandingFrontUrl?: Maybe<Scalars['String']['output']>
  counterBrandingSideAUrl?: Maybe<Scalars['String']['output']>
  counterBrandingSideBUrl?: Maybe<Scalars['String']['output']>
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  event: Event
  extras?: Maybe<Array<Extra>>
  extrasDueDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  foodTypes?: Maybe<Array<BoothFoodTypeEnum>>
  id: Scalars['ID']['output']
  latitude?: Maybe<Scalars['String']['output']>
  location?: Maybe<Location>
  longitude?: Maybe<Scalars['String']['output']>
  oscPortalUrl?: Maybe<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
  premium: Scalars['Boolean']['output']
  products: Array<Product>
  showOnMap: Scalars['Boolean']['output']
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
  type?: Maybe<BoothTypeEnum>
  typeDisplayName: Scalars['String']['output']
}

/** Attributes for creating or updating a booth */
export type BoothAttributes = {
  backCoverDesign?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingFront?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingSideA?: InputMaybe<Scalars['Upload']['input']>
  counterBrandingSideB?: InputMaybe<Scalars['Upload']['input']>
  /** Description DE */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** Description EN */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  foodTypes?: InputMaybe<Array<BoothFoodTypeEnum>>
  /** Title DE */
  titleDe?: InputMaybe<Scalars['String']['input']>
  /** Title EN */
  titleEn?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Booth. */
export type BoothConnection = {
  __typename?: 'BoothConnection'
  /** A list of edges. */
  edges: Array<BoothEdge>
  /** A list of nodes. */
  nodes: Array<Booth>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type BoothEdge = {
  __typename?: 'BoothEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Booth>
}

export enum BoothFoodTypeEnum {
  Fish = 'fish',
  Meat = 'meat',
  Vegan = 'vegan',
  Veggie = 'veggie',
}

export enum BoothTypeEnum {
  BrandedRoomBooth = 'BrandedRoomBooth',
  CoExhibitorBooth = 'CoExhibitorBooth',
  GastroBooth = 'GastroBooth',
  PremiumBooth = 'PremiumBooth',
  PremiumLoungeBooth = 'PremiumLoungeBooth',
  PremiumOutdoorBooth = 'PremiumOutdoorBooth',
  SamplingBooth = 'SamplingBooth',
  SeacontainerBooth = 'SeacontainerBooth',
  StartupBooth = 'StartupBooth',
  SystemBooth = 'SystemBooth',
}

export enum BucketEventTypeEnum {
  DigitalMasterclass = 'DigitalMasterclass',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  SideEvent = 'SideEvent',
}

export type BulkDiscount = {
  __typename?: 'BulkDiscount'
  net: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  ticketCategoryId: Scalars['String']['output']
}

/** Attributes for friendly captcha */
export type CaptchaAttributes = {
  /** sitekey */
  sitekey: Scalars['String']['input']
  /** solution */
  solution: Scalars['String']['input']
}

export type CheckoutConfig = {
  __typename?: 'CheckoutConfig'
  additionalAddress?: Maybe<Scalars['String']['output']>
  agbUrl?: Maybe<Scalars['String']['output']>
  bankaccountHash?: Maybe<Scalars['String']['output']>
  creditcardHash?: Maybe<Scalars['String']['output']>
  payoneAccountId?: Maybe<Scalars['String']['output']>
  payoneMerchantId?: Maybe<Scalars['String']['output']>
  payoneMode?: Maybe<Scalars['String']['output']>
  payonePortalId?: Maybe<Scalars['String']['output']>
  privacyUrl?: Maybe<Scalars['String']['output']>
}

export type CheckoutLineItem = {
  __typename?: 'CheckoutLineItem'
  externalId?: Maybe<Scalars['String']['output']>
  invoiceUrl?: Maybe<Scalars['String']['output']>
  isGross?: Maybe<Scalars['Boolean']['output']>
  quantity?: Maybe<Scalars['Int']['output']>
  singleAmount?: Maybe<Scalars['Int']['output']>
  ticketCategoryId?: Maybe<Scalars['ID']['output']>
  title?: Maybe<Scalars['String']['output']>
  type?: Maybe<LineItemTypeEnum>
  vat?: Maybe<Scalars['Int']['output']>
}

export type CheckoutOrder = {
  __typename?: 'CheckoutOrder'
  /** Status of the order. requested = only our system / link sent, appointed = sent to payone */
  aasmState: OrderAasmStateEnum
  /** Additional Address */
  additionalAddress?: Maybe<Scalars['String']['output']>
  /** Checkout Token */
  afterPaymentToken?: Maybe<Scalars['String']['output']>
  amountInCentsGross: Scalars['Int']['output']
  business?: Maybe<Scalars['Boolean']['output']>
  /** City */
  city?: Maybe<Scalars['String']['output']>
  /** Company name */
  company?: Maybe<Scalars['String']['output']>
  config?: Maybe<CheckoutConfig>
  /** Country */
  country?: Maybe<Scalars['String']['output']>
  /** Customer reference */
  customerReference?: Maybe<Scalars['String']['output']>
  /** E-Mail */
  email?: Maybe<Scalars['String']['output']>
  /** Event the order is for */
  event?: Maybe<Event>
  /** First name */
  firstName?: Maybe<Scalars['String']['output']>
  /** invoice_appendix */
  invoiceAppendix?: Maybe<Scalars['String']['output']>
  /** invoice_number */
  invoiceNumber?: Maybe<Scalars['String']['output']>
  /** Last Name */
  lastName?: Maybe<Scalars['String']['output']>
  lastOrder?: Maybe<CheckoutOrder>
  lineItems?: Maybe<Array<CheckoutLineItem>>
  number?: Maybe<Scalars['String']['output']>
  /** Payment method */
  paymentMethod?: Maybe<Scalars['String']['output']>
  redirectUrl?: Maybe<Scalars['String']['output']>
  /** ??? */
  reference?: Maybe<Scalars['String']['output']>
  /** Salutation */
  salutation?: Maybe<Scalars['String']['output']>
  /** Street */
  street?: Maybe<Scalars['String']['output']>
  summary?: Maybe<Summary>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
  /** Order type */
  type?: Maybe<OrderTypeEnum>
  /** VAT */
  vat?: Maybe<Scalars['Int']['output']>
  /** VAT Number */
  vatNumber?: Maybe<Scalars['String']['output']>
  /** Postal code */
  zip?: Maybe<Scalars['Int']['output']>
}

export type CheckoutQuery = {
  __typename?: 'CheckoutQuery'
  /** Order details and config */
  order: CheckoutOrder
}

export type CheckoutQueryOrderArgs = {
  afterPaymentToken: Scalars['String']['input']
}

/** Autogenerated input type of CloseAttendeeSelection */
export type CloseAttendeeSelectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
}

/** Autogenerated return type of CloseAttendeeSelection. */
export type CloseAttendeeSelectionPayload = {
  __typename?: 'CloseAttendeeSelectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  event?: Maybe<Event>
}

export enum CollectionUpdateEnum {
  Add = 'add',
  Delete = 'delete',
}

export enum CompanyDepartmentEnum {
  Finance = 'finance',
  Hr = 'hr',
  It = 'it',
  Logistic = 'logistic',
  Management = 'management',
  Marketing = 'marketing',
  Other = 'other',
  Production = 'production',
  Research = 'research',
  Sales = 'sales',
  Service = 'service',
}

export type CompanyDepartments = {
  __typename?: 'CompanyDepartments'
  enum: CompanyDepartmentEnum
  name: Scalars['String']['output']
}

export type CompanyFields = {
  __typename?: 'CompanyFields'
  companyIndustryId: Scalars['Int']['output']
  id: Scalars['Int']['output']
  /** @deprecated Use company_industry_id */
  parentId: Scalars['Int']['output']
  translation: Scalars['String']['output']
}

export type CompanyIndustries = {
  __typename?: 'CompanyIndustries'
  id: Scalars['Int']['output']
  translation: Scalars['String']['output']
}

export enum CompanySizeEnum {
  MoreThan_0 = 'more_than_0',
  MoreThan_10_001 = 'more_than_10_001',
  MoreThan_11 = 'more_than_11',
  MoreThan_51 = 'more_than_51',
  MoreThan_100_000 = 'more_than_100_000',
  MoreThan_101 = 'more_than_101',
  MoreThan_201 = 'more_than_201',
  MoreThan_501 = 'more_than_501',
  MoreThan_1001 = 'more_than_1001',
  MoreThan_2001 = 'more_than_2001',
  MoreThan_5001 = 'more_than_5001',
}

export type CompanySizes = {
  __typename?: 'CompanySizes'
  enum: CompanySizeEnum
  name: Scalars['String']['output']
}

export enum CompanyTypeEnum {
  Advertiser = 'advertiser',
  Agency = 'agency',
  Brand = 'brand',
  Other = 'other',
  Publisher = 'publisher',
  ServiceProvider = 'service_provider',
  Vendor = 'vendor',
}

export type CompanyTypes = {
  __typename?: 'CompanyTypes'
  enum: CompanyTypeEnum
  name: Scalars['String']['output']
}

export type Contact = {
  __typename?: 'Contact'
  email?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name?: Maybe<Scalars['String']['output']>
  partnerCompany: PartnerCompany
  phone?: Maybe<Scalars['String']['output']>
  salutation?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type: ContactTypeEnum
}

/** Attributes for creating or updating a contact */
export type ContactAttributes = {
  /** E-Mail */
  email?: InputMaybe<Scalars['String']['input']>
  /** First name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** Last name */
  name?: InputMaybe<Scalars['String']['input']>
  /** Phone */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Salutation */
  salutation?: InputMaybe<Scalars['String']['input']>
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>
  /** EventContact or SalesContact */
  type?: InputMaybe<Scalars['String']['input']>
}

/** Attributes for creating a contact transaction */
export type ContactTransactionAttributes = {
  /** Contact Transaction Type */
  contactTransactionType?: InputMaybe<ContactTransactionTypeEnum>
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>
  /** Reviews product slug */
  reviewsProductSlug?: InputMaybe<Scalars['String']['input']>
  /** Sender Company */
  senderCompany?: InputMaybe<Scalars['String']['input']>
  /** Sender Company Position */
  senderCompanyPosition?: InputMaybe<Scalars['String']['input']>
  /** Sender Email */
  senderEmail?: InputMaybe<Scalars['String']['input']>
  /** Sender First Name */
  senderFirstname?: InputMaybe<Scalars['String']['input']>
  /** Sender Last Name */
  senderLastname?: InputMaybe<Scalars['String']['input']>
  /** Sender First Name */
  senderName?: InputMaybe<Scalars['String']['input']>
  /** Sender Phone */
  senderPhone?: InputMaybe<Scalars['String']['input']>
  /** Sender Website */
  senderWebsite?: InputMaybe<Scalars['String']['input']>
}

export enum ContactTransactionTypeEnum {
  ContactForm = 'contact_form',
  CustomQuote = 'custom_quote',
}

export enum ContactTypeEnum {
  EventContact = 'EventContact',
  SalesContact = 'SalesContact',
}

export type ContentStream = Node & {
  __typename?: 'ContentStream'
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  event: Event
  id: Scalars['ID']['output']
  interests?: Maybe<Array<ActsAsTaggableOn>>
  name: Scalars['String']['output']
  slug?: Maybe<Scalars['String']['output']>
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
}

/** The connection type for ContentStream. */
export type ContentStreamConnection = {
  __typename?: 'ContentStreamConnection'
  /** A list of edges. */
  edges: Array<ContentStreamEdge>
  /** A list of nodes. */
  nodes: Array<ContentStream>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ContentStreamEdge = {
  __typename?: 'ContentStreamEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ContentStream>
}

export type Country = {
  __typename?: 'Country'
  code: Scalars['String']['output']
  translation: Scalars['String']['output']
  translations: CountryTranslations
}

export type CountryTranslations = {
  __typename?: 'CountryTranslations'
  de: Scalars['String']['output']
  en: Scalars['String']['output']
}

/** Autogenerated input type of CreateAcknowledgement */
export type CreateAcknowledgementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  documentId: Scalars['ID']['input']
  resourceId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateAcknowledgement. */
export type CreateAcknowledgementPayload = {
  __typename?: 'CreateAcknowledgementPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  docAcknowledgement?: Maybe<DocAcknowledgement>
  errors?: Maybe<Array<Scalars['String']['output']>>
}

/** Autogenerated input type of CreateContact */
export type CreateContactInput = {
  attributes: ContactAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateContact. */
export type CreateContactPayload = {
  __typename?: 'CreateContactPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contact?: Maybe<Contact>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of CreateContactTransaction */
export type CreateContactTransactionInput = {
  attributes: ContactTransactionAttributes
  captcha?: InputMaybe<CaptchaAttributes>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  companySlug?: InputMaybe<Scalars['String']['input']>
  contactId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateContactTransaction. */
export type CreateContactTransactionPayload = {
  __typename?: 'CreateContactTransactionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateDirectTicket */
export type CreateDirectTicketInput = {
  attributes: DirectTicketAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateDirectTicket. */
export type CreateDirectTicketPayload = {
  __typename?: 'CreateDirectTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of CreateEventSpeaker */
export type CreateEventSpeakerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  speakerId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateEventSpeaker. */
export type CreateEventSpeakerPayload = {
  __typename?: 'CreateEventSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  eventSpeaker?: Maybe<EventSpeaker>
}

/** Autogenerated input type of CreateInboundContact */
export type CreateInboundContactInput = {
  attributes: InboundContactAttributes
  captcha?: InputMaybe<CaptchaAttributes>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateInboundContact. */
export type CreateInboundContactPayload = {
  __typename?: 'CreateInboundContactPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateIndividualInvitees */
export type CreateIndividualInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
  invitees: Array<InviteeInput>
}

/** Autogenerated return type of CreateIndividualInvitees. */
export type CreateIndividualInviteesPayload = {
  __typename?: 'CreateIndividualInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  invitationQuota?: Maybe<InvitationQuota>
}

/** Autogenerated input type of Create */
export type CreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  dueDateItemId: Scalars['ID']['input']
}

/** Autogenerated input type of CreateInternalAccessCheck */
export type CreateInternalAccessCheckInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  ticketId: Scalars['ID']['input']
  viewingSessionId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateInternalAccessCheck. */
export type CreateInternalAccessCheckPayload = {
  __typename?: 'CreateInternalAccessCheckPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  internalAccessCheck: InternalAccessCheck
}

/** Autogenerated input type of CreateLead */
export type CreateLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  scannedTicket: Scalars['String']['input']
  tags?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateLead. */
export type CreateLeadPayload = {
  __typename?: 'CreateLeadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  lead?: Maybe<Lead>
}

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  inviteeToken?: InputMaybe<Scalars['String']['input']>
  origin?: InputMaybe<Scalars['String']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  ticketCategories: Array<TicketCategoryInput>
  utmParams?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateOrder. */
export type CreateOrderPayload = {
  __typename?: 'CreateOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  order?: Maybe<CheckoutOrder>
}

/** Autogenerated input type of CreatePartnerCompanyWithHubspotId */
export type CreatePartnerCompanyWithHubspotIdInput = {
  city?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['String']['input']>
  hubspotId: Scalars['String']['input']
  name: Scalars['String']['input']
  slug?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
  zipcode?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreatePartnerCompanyWithHubspotId. */
export type CreatePartnerCompanyWithHubspotIdPayload = {
  __typename?: 'CreatePartnerCompanyWithHubspotIdPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
}

/** Autogenerated return type of Create. */
export type CreatePayload = {
  __typename?: 'CreatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  dueDateItems: Array<DueDateItem>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateProspectItem */
export type CreateProspectItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
  token: Scalars['ID']['input']
}

/** Autogenerated return type of CreateProspectItem. */
export type CreateProspectItemPayload = {
  __typename?: 'CreateProspectItemPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateProspectList */
export type CreateProspectListInput = {
  attributes: ProspectListAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateProspectList. */
export type CreateProspectListPayload = {
  __typename?: 'CreateProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  prospectList?: Maybe<ProspectList>
}

/** Autogenerated input type of CreateReviewsManager */
export type CreateReviewsManagerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  partnerCompanySlug: Scalars['String']['input']
  personalMessage?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateReviewsManager. */
export type CreateReviewsManagerPayload = {
  __typename?: 'CreateReviewsManagerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Autogenerated input type of CreateSpeaker */
export type CreateSpeakerInput = {
  attributes: SpeakerAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of CreateSpeaker. */
export type CreateSpeakerPayload = {
  __typename?: 'CreateSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  speaker?: Maybe<Speaker>
}

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Email */
  email: Scalars['String']['input']
  personalMessage?: InputMaybe<Scalars['String']['input']>
  /** Role names */
  roleNames?: InputMaybe<Array<RoleNameEnum>>
}

/** Autogenerated return type of CreateUser. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Attributes for defining csv column to invitee attribute mapping */
export type CsvMappingAttributes = {
  cc?: InputMaybe<Scalars['Int']['input']>
  company?: InputMaybe<Scalars['Int']['input']>
  dateOfBirth?: InputMaybe<Scalars['Int']['input']>
  email: Scalars['Int']['input']
  externalId?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['Int']['input']>
  gender?: InputMaybe<Scalars['Int']['input']>
  lastName?: InputMaybe<Scalars['Int']['input']>
  partnerName?: InputMaybe<Scalars['Int']['input']>
  partnerRole?: InputMaybe<Scalars['Int']['input']>
  phone?: InputMaybe<Scalars['Int']['input']>
  position?: InputMaybe<Scalars['Int']['input']>
  title?: InputMaybe<Scalars['Int']['input']>
}

export type CsvPreviewData = {
  __typename?: 'CsvPreviewData'
  index: Scalars['Int']['output']
  name: Scalars['String']['output']
  sample: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeactivateTicket */
export type DeactivateTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of DeactivateTicket. */
export type DeactivateTicketPayload = {
  __typename?: 'DeactivateTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of DeleteAcknowledgementUpload */
export type DeleteAcknowledgementUploadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  docAcknowledgementId: Scalars['ID']['input']
  uploadUrl: Scalars['String']['input']
}

/** Autogenerated return type of DeleteAcknowledgementUpload. */
export type DeleteAcknowledgementUploadPayload = {
  __typename?: 'DeleteAcknowledgementUploadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  docAcknowledgement?: Maybe<DocAcknowledgement>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeleteEventSpeaker */
export type DeleteEventSpeakerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventSpeakerId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteEventSpeaker. */
export type DeleteEventSpeakerPayload = {
  __typename?: 'DeleteEventSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of DeleteIndividualInvitees */
export type DeleteIndividualInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emails: Array<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteIndividualInvitees. */
export type DeleteIndividualInviteesPayload = {
  __typename?: 'DeleteIndividualInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of Delete */
export type DeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  dueDateItemId: Scalars['ID']['input']
}

/** Autogenerated return type of Delete. */
export type DeletePayload = {
  __typename?: 'DeletePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  dueDateItems: Array<DueDateItem>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of DeleteProspectItem */
export type DeleteProspectItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
  token: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteProspectItem. */
export type DeleteProspectItemPayload = {
  __typename?: 'DeleteProspectItemPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of DeleteProspectList */
export type DeleteProspectListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteProspectList. */
export type DeleteProspectListPayload = {
  __typename?: 'DeleteProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeleteTicket */
export type DeleteTicketInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteTicket. */
export type DeleteTicketPayload = {
  __typename?: 'DeleteTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DeleteUnsentInvitees */
export type DeleteUnsentInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of DeleteUnsentInvitees. */
export type DeleteUnsentInviteesPayload = {
  __typename?: 'DeleteUnsentInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of Deny */
export type DenyInput = {
  attendeeId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of Deny. */
export type DenyPayload = {
  __typename?: 'DenyPayload'
  attendee?: Maybe<Attendee>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of DenyTicketRequest */
export type DenyTicketRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of DenyTicketRequest. */
export type DenyTicketRequestPayload = {
  __typename?: 'DenyTicketRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

export type DigitalMasterclass = Node & {
  __typename?: 'DigitalMasterclass'
  agendaFormatted?: Maybe<Scalars['String']['output']>
  attendeeCount: Scalars['Int']['output']
  attendeeSelectionClosed: Scalars['Boolean']['output']
  attendeeSelectionPaused: Scalars['Boolean']['output']
  attendees?: Maybe<AttendeeConnection>
  booths?: Maybe<BoothConnection>
  companyEmail?: Maybe<Scalars['String']['output']>
  companyLogoUrl?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyWebsite?: Maybe<Scalars['String']['output']>
  ctaButtonLink?: Maybe<Scalars['String']['output']>
  ctaButtonText?: Maybe<Scalars['String']['output']>
  ctaButtonTextDe?: Maybe<Scalars['String']['output']>
  ctaButtonTextEn?: Maybe<Scalars['String']['output']>
  description: Scalars['String']['output']
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['String']['output']>
  durationInMinutes?: Maybe<Scalars['Int']['output']>
  enableCheckin: Scalars['Boolean']['output']
  endsAt: Scalars['ISO8601DateTime']['output']
  eventFormat?: Maybe<Scalars['String']['output']>
  featured: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  idHash: Scalars['String']['output']
  interests: Array<Interest>
  invitationQuotas?: Maybe<Array<InvitationQuota>>
  invitationQuotasCount: Scalars['Int']['output']
  language?: Maybe<LanguageEnum>
  leadCount: Scalars['Int']['output']
  livestreamUrl?: Maybe<Scalars['String']['output']>
  livestreamUrlWithToken?: Maybe<Scalars['String']['output']>
  location?: Maybe<Location>
  locationAddress?: Maybe<Scalars['String']['output']>
  locationName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use website_background instead */
  logoUrl?: Maybe<Scalars['String']['output']>
  masterclassApplicationOpenUntil?: Maybe<Scalars['String']['output']>
  maxAttendees?: Maybe<Scalars['Int']['output']>
  minAttendees?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  nameDe?: Maybe<Scalars['String']['output']>
  nameEn?: Maybe<Scalars['String']['output']>
  partnerCompanies: Array<PartnerCompany>
  partnerCompanyId?: Maybe<Scalars['ID']['output']>
  partnerCompanyListing?: Maybe<Array<PartnerCompany>>
  presentationFormatted?: Maybe<Scalars['String']['output']>
  presentationRaw?: Maybe<Scalars['String']['output']>
  previewCardBackgroundUrl?: Maybe<Scalars['String']['output']>
  prospectSelectionUntil?: Maybe<Scalars['ISO8601DateTime']['output']>
  relatedOmrReviewsSlug?: Maybe<Scalars['String']['output']>
  reservedSeats: Scalars['Int']['output']
  shortName?: Maybe<Scalars['String']['output']>
  shuttleService?: Maybe<Scalars['String']['output']>
  skipBillingInfo?: Maybe<Scalars['Boolean']['output']>
  slug: Scalars['String']['output']
  speakers: Array<Speaker>
  stage?: Maybe<Stage>
  stageId?: Maybe<Scalars['ID']['output']>
  startsAt: Scalars['ISO8601DateTime']['output']
  streamingKey?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  subtitleDe?: Maybe<Scalars['String']['output']>
  subtitleEn?: Maybe<Scalars['String']['output']>
  ticketCreationDeadlineHint?: Maybe<Scalars['ISO8601DateTime']['output']>
  ticketId: Scalars['ID']['output']
  ticketShop: Scalars['Boolean']['output']
  /** @deprecated title is deprecated, use name instead */
  title?: Maybe<Scalars['String']['output']>
  /** @deprecated title_de is deprecated, use name_de instead */
  titleDe?: Maybe<Scalars['String']['output']>
  /** @deprecated title_en is deprecated, use name_en instead */
  titleEn?: Maybe<Scalars['String']['output']>
  type?: Maybe<EventTypeEnum>
  videoFinalDuration: Scalars['Int']['output']
  videoFinalUrl: Scalars['String']['output']
  videoPresentationUrl?: Maybe<Scalars['String']['output']>
  videoRecordingUrl?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  websiteBackgroundUrl?: Maybe<Scalars['String']['output']>
}

export type DigitalMasterclassAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type DigitalMasterclassBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type DigitalMasterclassPartnerCompanyListingArgs = {
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
}

/** Attributes for creating or updating ticket from Crew or Exh Quotas */
export type DirectTicketAttributes = {
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>
  companyDepartment?: InputMaybe<CompanyDepartmentEnum>
  companyFieldId?: InputMaybe<Scalars['Int']['input']>
  companyIndustryId?: InputMaybe<Scalars['Int']['input']>
  companyName: Scalars['String']['input']
  companySize?: InputMaybe<CompanySizeEnum>
  country?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  jobLevel?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  partnerName?: InputMaybe<Scalars['String']['input']>
  partnerRole?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  position: Scalars['String']['input']
  salutation: SalutationTypeEnum
  title?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of Disconnect */
export type DisconnectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  provider: OauthProviderEnum
}

/** Autogenerated return type of Disconnect. */
export type DisconnectPayload = {
  __typename?: 'DisconnectPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  me: User
  success: Scalars['Boolean']['output']
}

export type DocAcknowledgement = {
  __typename?: 'DocAcknowledgement'
  acknowledgedAt?: Maybe<Scalars['String']['output']>
  acknowledgedBy?: Maybe<Scalars['ID']['output']>
  document: Document
  id: Scalars['ID']['output']
  signableId: Scalars['ID']['output']
  signableType: Scalars['String']['output']
  uploadedAt?: Maybe<Scalars['String']['output']>
  uploadedBy?: Maybe<Scalars['ID']['output']>
  uploadsUrls: Array<Scalars['String']['output']>
}

/** The connection type for DocAcknowledgement. */
export type DocAcknowledgementConnection = {
  __typename?: 'DocAcknowledgementConnection'
  /** A list of edges. */
  edges: Array<DocAcknowledgementEdge>
  /** A list of nodes. */
  nodes: Array<DocAcknowledgement>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type DocAcknowledgementEdge = {
  __typename?: 'DocAcknowledgementEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<DocAcknowledgement>
}

export type Document = Node & {
  __typename?: 'Document'
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  docAcknowledgements?: Maybe<Array<DocAcknowledgement>>
  documentUrl?: Maybe<Scalars['String']['output']>
  eventId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  requireAcknowledge: Scalars['Boolean']['output']
  requireUpload: Scalars['Boolean']['output']
  title?: Maybe<Scalars['String']['output']>
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
}

/** The connection type for Document. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection'
  /** A list of edges. */
  edges: Array<DocumentEdge>
  /** A list of nodes. */
  nodes: Array<Document>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Document>
}

export type DueDateItem = Node & {
  __typename?: 'DueDateItem'
  /** Due date was marked as completed by partner company */
  completed: Scalars['Boolean']['output']
  /** Due At */
  dueAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  /** ID of the resource to update */
  resourceId?: Maybe<Scalars['ID']['output']>
  /** Resource type. Indicate what kind of action is required. */
  resourceType: Scalars['String']['output']
  /** German title */
  titleDe: Scalars['String']['output']
  /** English title */
  titleEn: Scalars['String']['output']
  /** Indicates if due date requirements were fulfilled */
  valid: Scalars['Boolean']['output']
}

/** Autogenerated input type of EditEmailTemplate */
export type EditEmailTemplateInput = {
  body: Scalars['String']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emailCc?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
  replyTo: Scalars['String']['input']
  subject: Scalars['String']['input']
}

/** Autogenerated return type of EditEmailTemplate. */
export type EditEmailTemplatePayload = {
  __typename?: 'EditEmailTemplatePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  invitationQuota?: Maybe<InvitationQuota>
}

export type EducationReportMatch = {
  __typename?: 'EducationReportMatch'
  description: Scalars['String']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  maxPrice?: Maybe<Scalars['String']['output']>
  minPrice?: Maybe<Scalars['String']['output']>
  priceCurrency?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type EducationReportMatchPaginatedCollection = {
  __typename?: 'EducationReportMatchPaginatedCollection'
  nodes: Array<EducationReportMatch>
  pageInfo: OffsetPaginationInfo
}

export type EducationSeminarMatch = {
  __typename?: 'EducationSeminarMatch'
  description: Scalars['String']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  maxPrice?: Maybe<Scalars['String']['output']>
  minPrice?: Maybe<Scalars['String']['output']>
  priceCurrency?: Maybe<Scalars['String']['output']>
  productType?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type EducationSeminarMatchPaginatedCollection = {
  __typename?: 'EducationSeminarMatchPaginatedCollection'
  nodes: Array<EducationSeminarMatch>
  pageInfo: OffsetPaginationInfo
}

export type Error = {
  __typename?: 'Error'
  ticketCategoryId?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
}

export type Event = Node & {
  __typename?: 'Event'
  agendaFormatted?: Maybe<Scalars['String']['output']>
  attendeeCount: Scalars['Int']['output']
  attendeeSelectionClosed: Scalars['Boolean']['output']
  attendeeSelectionPaused: Scalars['Boolean']['output']
  attendees?: Maybe<AttendeeConnection>
  booths?: Maybe<BoothConnection>
  companyEmail?: Maybe<Scalars['String']['output']>
  companyLogoUrl?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyWebsite?: Maybe<Scalars['String']['output']>
  ctaButtonLink?: Maybe<Scalars['String']['output']>
  ctaButtonText?: Maybe<Scalars['String']['output']>
  ctaButtonTextDe?: Maybe<Scalars['String']['output']>
  ctaButtonTextEn?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['String']['output']>
  durationInMinutes?: Maybe<Scalars['Int']['output']>
  enableCheckin: Scalars['Boolean']['output']
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  eventFormat?: Maybe<Scalars['String']['output']>
  featured: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  interests: Array<Interest>
  invitationQuotas?: Maybe<Array<InvitationQuota>>
  invitationQuotasCount: Scalars['Int']['output']
  language?: Maybe<LanguageEnum>
  leadCount: Scalars['Int']['output']
  livestreamUrl?: Maybe<Scalars['String']['output']>
  livestreamUrlWithToken?: Maybe<Scalars['String']['output']>
  location?: Maybe<Location>
  locationAddress?: Maybe<Scalars['String']['output']>
  locationName?: Maybe<Scalars['String']['output']>
  /** @deprecated Use website_background instead */
  logoUrl?: Maybe<Scalars['String']['output']>
  masterclassApplicationOpenUntil?: Maybe<Scalars['String']['output']>
  maxAttendees?: Maybe<Scalars['Int']['output']>
  minAttendees?: Maybe<Scalars['Int']['output']>
  name?: Maybe<Scalars['String']['output']>
  nameDe?: Maybe<Scalars['String']['output']>
  nameEn?: Maybe<Scalars['String']['output']>
  partnerCompanies: Array<PartnerCompany>
  partnerCompanyId?: Maybe<Scalars['ID']['output']>
  partnerCompanyListing?: Maybe<Array<PartnerCompany>>
  presentationFormatted?: Maybe<Scalars['String']['output']>
  presentationRaw?: Maybe<Scalars['String']['output']>
  previewCardBackgroundUrl?: Maybe<Scalars['String']['output']>
  prospectSelectionUntil?: Maybe<Scalars['ISO8601DateTime']['output']>
  relatedOmrReviewsSlug?: Maybe<Scalars['String']['output']>
  reservedSeats: Scalars['Int']['output']
  shortName?: Maybe<Scalars['String']['output']>
  shuttleService?: Maybe<Scalars['String']['output']>
  skipBillingInfo?: Maybe<Scalars['Boolean']['output']>
  slug?: Maybe<Scalars['String']['output']>
  speakers: Array<Speaker>
  stage?: Maybe<Stage>
  stageId?: Maybe<Scalars['ID']['output']>
  startsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  streamingKey?: Maybe<Scalars['String']['output']>
  subtitle?: Maybe<Scalars['String']['output']>
  subtitleDe?: Maybe<Scalars['String']['output']>
  subtitleEn?: Maybe<Scalars['String']['output']>
  ticketCreationDeadlineHint?: Maybe<Scalars['ISO8601DateTime']['output']>
  ticketShop: Scalars['Boolean']['output']
  /** @deprecated title is deprecated, use name instead */
  title?: Maybe<Scalars['String']['output']>
  /** @deprecated title_de is deprecated, use name_de instead */
  titleDe?: Maybe<Scalars['String']['output']>
  /** @deprecated title_en is deprecated, use name_en instead */
  titleEn?: Maybe<Scalars['String']['output']>
  type?: Maybe<EventTypeEnum>
  videoPresentationUrl?: Maybe<Scalars['String']['output']>
  videoRecordingUrl?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  websiteBackgroundUrl?: Maybe<Scalars['String']['output']>
}

export type EventAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EventBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EventPartnerCompanyListingArgs = {
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type EventApplication = {
  __typename?: 'EventApplication'
  aasmState: AttendeeAasmStateEnum
  addedViaManage: Scalars['Boolean']['output']
  event: PublishedEvent
  id: Scalars['ID']['output']
}

export enum EventApplicationPhaseEnum {
  Application = 'application',
  Fcfs = 'fcfs',
  Finished = 'finished',
  Lead = 'lead',
}

/** Attributes for creating or updating an event */
export type EventAttributes = {
  /** agenda timestamps json */
  agendaFormatted?: InputMaybe<Scalars['String']['input']>
  /** agenda timestamps for topics */
  agendaRaw?: InputMaybe<Scalars['String']['input']>
  /** Events company email */
  companyEmail?: InputMaybe<Scalars['String']['input']>
  /** Events company name */
  companyName?: InputMaybe<Scalars['String']['input']>
  /** Events company website */
  companyWebsite?: InputMaybe<Scalars['String']['input']>
  /** Event cta link */
  ctaButtonLink?: InputMaybe<Scalars['String']['input']>
  /** Event cta text */
  ctaButtonText?: InputMaybe<Scalars['String']['input']>
  /** Event description (DE) */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** Event description (EN) */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  /** Event duration */
  duration?: InputMaybe<Scalars['String']['input']>
  /** Event ends at */
  endsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Event format */
  eventFormat?: InputMaybe<Scalars['String']['input']>
  /** Interests */
  interests?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Event language */
  language?: InputMaybe<Scalars['String']['input']>
  /** Event location address override */
  locationAddress?: InputMaybe<Scalars['String']['input']>
  /** Event location override */
  locationName?: InputMaybe<Scalars['String']['input']>
  /** Event max attendees */
  maxAttendees?: InputMaybe<Scalars['Int']['input']>
  /** Event name */
  nameDe?: InputMaybe<Scalars['String']['input']>
  /** Event name */
  nameEn?: InputMaybe<Scalars['String']['input']>
  /** presentation timestamps json */
  presentationFormatted?: InputMaybe<Scalars['String']['input']>
  /** presentation timestamps */
  presentationRaw?: InputMaybe<Scalars['String']['input']>
  /** Shuttle Service */
  shuttleService?: InputMaybe<Scalars['Boolean']['input']>
  /** Event starts at */
  startsAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Streaming key */
  streamingKey?: InputMaybe<Scalars['String']['input']>
  /** Event subtitle (DE) */
  subtitleDe?: InputMaybe<Scalars['String']['input']>
  /** Event subtitle (EN) */
  subtitleEn?: InputMaybe<Scalars['String']['input']>
  /** processed video file */
  videoFinal?: InputMaybe<Scalars['String']['input']>
  /** video file for presentation */
  videoPresentation?: InputMaybe<Scalars['Upload']['input']>
  /** video file for speaker recording */
  videoRecording?: InputMaybe<Scalars['Upload']['input']>
  websiteBackground?: InputMaybe<Scalars['Upload']['input']>
}

/** The connection type for Event. */
export type EventConnectionWithTotalCount = {
  __typename?: 'EventConnectionWithTotalCount'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Event>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type EventEdge = {
  __typename?: 'EventEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Event>
}

export type EventNotification = {
  __typename?: 'EventNotification'
  id: Scalars['ID']['output']
  message: Scalars['String']['output']
  title: Scalars['String']['output']
}

export enum EventPeriodEnum {
  Past = 'past',
  Upcoming = 'upcoming',
}

export type EventSpeaker = Node & {
  __typename?: 'EventSpeaker'
  event: Event
  id: Scalars['ID']['output']
  speaker: Speaker
}

export type EventStarringsUpdate = {
  eventId: Scalars['ID']['input']
  type: CollectionUpdateEnum
}

export enum EventTypeEnum {
  BreakEvent = 'BreakEvent',
  CareerMeetup = 'CareerMeetup',
  DeepDive = 'DeepDive',
  DigitalMasterclass = 'DigitalMasterclass',
  Event = 'Event',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  SideEvent = 'SideEvent',
  StageSlot = 'StageSlot',
}

export type EventsForApplication = {
  __typename?: 'EventsForApplication'
  applicationPhase?: Maybe<EventApplicationPhaseEnum>
  eventTypes: Array<BucketEventTypeEnum>
  events: Array<PublishedEvent>
  maxApplications: Scalars['Int']['output']
  withinPeriod: Scalars['Boolean']['output']
}

/** Autogenerated input type of ExportLeads */
export type ExportLeadsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  eventSlug: Scalars['String']['input']
}

/** Autogenerated return type of ExportLeads. */
export type ExportLeadsPayload = {
  __typename?: 'ExportLeadsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type Extra = {
  __typename?: 'Extra'
  comment?: Maybe<Scalars['String']['output']>
  count: Scalars['Int']['output']
  deliveryFee?: Maybe<Scalars['Float']['output']>
  fileName?: Maybe<Scalars['String']['output']>
  fileUrl?: Maybe<Scalars['String']['output']>
  price: Scalars['Float']['output']
  productId: Scalars['ID']['output']
  selectedVariantId: Scalars['ID']['output']
}

/** Attributes for creating or updating extras */
export type ExtraAttributes = {
  comment?: InputMaybe<Scalars['String']['input']>
  count: Scalars['Int']['input']
  file?: InputMaybe<Scalars['Upload']['input']>
  productId: Scalars['ID']['input']
  variantId: Scalars['ID']['input']
}

export type Facet = {
  __typename?: 'Facet'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Field = {
  __typename?: 'Field'
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  getId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  key?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type FieldCategory = {
  __typename?: 'FieldCategory'
  getBadgeColor?: Maybe<Scalars['String']['output']>
  getWristbandColor?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  key: FieldCategoryEnum
  name: Scalars['String']['output']
}

export enum FieldCategoryEnum {
  Construction = 'construction',
  Crew = 'crew',
  Exhibitor = 'exhibitor',
  Guest = 'guest',
  GuestFfwd = 'guest_ffwd',
  Press = 'press',
  PressFfwd = 'press_ffwd',
  Speaker = 'speaker',
}

export enum GenderEnum {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}

export type GeojsonMaps = {
  __typename?: 'GeojsonMaps'
  eventSlug: Scalars['String']['output']
  features: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  revisionId: Scalars['ID']['output']
}

export type HygraphAuthor = {
  __typename?: 'HygraphAuthor'
  name: Scalars['String']['output']
}

/** Autogenerated input type of ImportInvitees */
export type ImportInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  csvUploadId: Scalars['ID']['input']
  hasHeaders: Scalars['Boolean']['input']
  invitationQuotaId: Scalars['ID']['input']
  mapping: CsvMappingAttributes
}

/** Autogenerated return type of ImportInvitees. */
export type ImportInviteesPayload = {
  __typename?: 'ImportInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
  withErrorsCount: Scalars['Int']['output']
  withoutErrorsCount: Scalars['Int']['output']
}

/** Attributes for creating an inbound contact */
export type InboundContactAttributes = {
  /** Company */
  company: Scalars['String']['input']
  /** Email */
  email: Scalars['String']['input']
  /** First Name */
  firstname: Scalars['String']['input']
  /** How did you hear about us? */
  howDidYouHearAboutUs?: InputMaybe<SourceEnum>
  /** Last Name */
  lastname: Scalars['String']['input']
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>
  /** Phone */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Website */
  position?: InputMaybe<Scalars['String']['input']>
  /** What would you like to talk to us about? */
  whatWouldYouLikeToTalkToUsAbout: InterestEnum
}

export type Interest = Node & {
  __typename?: 'Interest'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  /** @deprecated deprecated, use name instead */
  text: Scalars['String']['output']
}

/** The connection type for Interest. */
export type InterestConnection = {
  __typename?: 'InterestConnection'
  /** A list of edges. */
  edges: Array<InterestEdge>
  /** A list of nodes. */
  nodes: Array<Interest>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type InterestEdge = {
  __typename?: 'InterestEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Interest>
}

export enum InterestEnum {
  Branding = 'branding',
  ContentPartnership = 'content_partnership',
  DigitalMasterclass = 'digital_masterclass',
  FestivalBooth = 'festival_booth',
  IndividualRequest = 'individual_request',
  Masterclass = 'masterclass',
  PartnershipInGeneral = 'partnership_in_general',
}

export type InterestsUpdate = {
  interestId: Scalars['ID']['input']
  type: CollectionUpdateEnum
}

export type InternalAccessCheck = {
  __typename?: 'InternalAccessCheck'
  id: Scalars['ID']['output']
  ticket: Ticket
  user: User
  viewingSessionId: Scalars['ID']['output']
}

export type InvitationQuota = Node & {
  __typename?: 'InvitationQuota'
  activatedTicketsCount?: Maybe<Scalars['Int']['output']>
  /** @deprecated deprecated */
  adminDescription?: Maybe<Scalars['String']['output']>
  allowUsageOfSameAddress: Scalars['Boolean']['output']
  cc?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['String']['output']
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  discount: Scalars['Int']['output']
  doNotSendOutTicketEmail: Scalars['Boolean']['output']
  event?: Maybe<Event>
  expirationDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  fields: Array<Field>
  id: Scalars['ID']['output']
  inviteeTracking?: Maybe<InviteeTracking>
  invitees?: Maybe<InviteeConnection>
  kind: InvitationQuotaTypeEnum
  maxUsable: Scalars['Int']['output']
  messageBody?: Maybe<Scalars['String']['output']>
  messageSubject?: Maybe<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
  promoCodes?: Maybe<Array<PromoCode>>
  replyTo?: Maybe<Scalars['String']['output']>
  showFormLink: Scalars['Boolean']['output']
  ticketCategory?: Maybe<TicketCategory>
  ticketsCount?: Maybe<Scalars['Int']['output']>
  title?: Maybe<Scalars['String']['output']>
  usableTicketsCount?: Maybe<Scalars['Int']['output']>
}

export type InvitationQuotaInviteesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The connection type for InvitationQuota. */
export type InvitationQuotaConnection = {
  __typename?: 'InvitationQuotaConnection'
  /** A list of edges. */
  edges: Array<InvitationQuotaEdge>
  /** A list of nodes. */
  nodes: Array<InvitationQuota>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type InvitationQuotaEdge = {
  __typename?: 'InvitationQuotaEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<InvitationQuota>
}

export enum InvitationQuotaTypeEnum {
  CodeQuota = 'CodeQuota',
  CrewQuota = 'CrewQuota',
  ExhibitorQuota = 'ExhibitorQuota',
  GuestQuota = 'GuestQuota',
  PressQuota = 'PressQuota',
}

export type Invitee = {
  __typename?: 'Invitee'
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  activated: Scalars['Boolean']['output']
  bounceDescription?: Maybe<Scalars['String']['output']>
  bouncedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  cc?: Maybe<Scalars['String']['output']>
  company?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  declinedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  email: Scalars['String']['output']
  externalId?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  gender?: Maybe<GenderEnum>
  lastName?: Maybe<Scalars['String']['output']>
  openedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  sentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  sentCount: Scalars['Int']['output']
  status: InviteeStatusEnum
  title?: Maybe<Scalars['String']['output']>
  token: Scalars['ID']['output']
}

/** The connection type for Invitee. */
export type InviteeConnection = {
  __typename?: 'InviteeConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InviteeEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invitee>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type InviteeEdge = {
  __typename?: 'InviteeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Invitee>
}

/** Input type for creating manual invitees */
export type InviteeInput = {
  /** Company */
  company?: InputMaybe<Scalars['String']['input']>
  /** Email */
  email: Scalars['String']['input']
  /** First name */
  firstName: Scalars['String']['input']
  /** Gender */
  gender?: InputMaybe<GenderEnum>
  /** Last name */
  lastName: Scalars['String']['input']
  /** Title */
  title?: InputMaybe<Scalars['String']['input']>
}

export type InviteePaginatedCollection = {
  __typename?: 'InviteePaginatedCollection'
  nodes: Array<Invitee>
  pageInfo: OffsetPaginationInfo
}

export enum InviteeStatusEnum {
  Accepted = 'accepted',
  Bounced = 'bounced',
  Declined = 'declined',
  Draft = 'draft',
  Opened = 'opened',
  Purchased = 'purchased',
  Sending = 'sending',
  Sent = 'sent',
}

export type InviteeTracking = {
  __typename?: 'InviteeTracking'
  acceptedCount: Scalars['Int']['output']
  declinedCount: Scalars['Int']['output']
  openedCount: Scalars['Int']['output']
  purchasedCount: Scalars['Int']['output']
  sentCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
}

export type Invoice = {
  __typename?: 'Invoice'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  invoiceUrl: Scalars['String']['output']
  number: Scalars['String']['output']
  transactionType: TransactionTypeEnum
}

export enum JobLevelEnum {
  BoardMember = 'board_member',
  CLevel = 'c_level',
  Ceo = 'ceo',
  Cfo = 'cfo',
  Cmo = 'cmo',
  Coo = 'coo',
  Cto = 'cto',
  Director = 'director',
  Entry = 'entry',
  HeadOf = 'head_of',
  Manager = 'manager',
  Other = 'other',
  Senior = 'senior',
  Student = 'student',
}

export type JobLevels = {
  __typename?: 'JobLevels'
  enum: JobLevelEnum
  name: Scalars['String']['output']
}

export type JobMatch = {
  __typename?: 'JobMatch'
  companyLogoThumb?: Maybe<Scalars['String']['output']>
  companyName: Scalars['String']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  locations: Array<Scalars['String']['output']>
  publishedAt: Scalars['ISO8601DateTime']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type JobMatchPaginatedCollection = {
  __typename?: 'JobMatchPaginatedCollection'
  nodes: Array<JobMatch>
  pageInfo: OffsetPaginationInfo
}

export enum JobsCrewProfessionalEnum {
  BakerAndPattesian = 'baker_and_pattesian',
  Barista = 'barista',
  Concierge = 'concierge',
  ContentCreation = 'content_creation',
  Cooking = 'cooking',
  FoodtruckManagement = 'foodtruck_management',
  ForkliftDriver = 'forklift_driver',
  InitiativeApplication = 'initiative_application',
  KitchenAssist = 'kitchen_assist',
  Logistics = 'logistics',
  ShuttleDriver = 'shuttle_driver',
  TruckDriver = 'truck_driver',
}

export type JsonLd = {
  __typename?: 'JsonLd'
  id: Scalars['ID']['output']
  json: Scalars['String']['output']
}

export type JwtMasterclass = {
  __typename?: 'JwtMasterclass'
  masterclass: DigitalMasterclass
  ticket: Ticket
}

export enum LanguageEnum {
  De = 'de',
  En = 'en',
}

export type Lead = {
  __typename?: 'Lead'
  id: Scalars['ID']['output']
  leadLinkId: Scalars['ID']['output']
  leadLinkType: Scalars['String']['output']
  note?: Maybe<Scalars['String']['output']>
  scannedCompanyName?: Maybe<Scalars['String']['output']>
  scannedEmail?: Maybe<Scalars['String']['output']>
  scannedFirstName?: Maybe<Scalars['String']['output']>
  scannedLastName?: Maybe<Scalars['String']['output']>
  scannedTicketIdentifier: Scalars['String']['output']
  tags?: Maybe<Array<Scalars['String']['output']>>
}

/** Attributes for creating or updating leads through syncing */
export type LeadAttributes = {
  note?: InputMaybe<Scalars['String']['input']>
  scannedTicket: Scalars['String']['input']
  tags?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Lead. */
export type LeadConnection = {
  __typename?: 'LeadConnection'
  /** A list of edges. */
  edges: Array<LeadEdge>
  /** A list of nodes. */
  nodes: Array<Lead>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type LeadEdge = {
  __typename?: 'LeadEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Lead>
}

export type LeadGenLimitConfig = Node & {
  __typename?: 'LeadGenLimitConfig'
  event: Event
  id: Scalars['ID']['output']
  limit?: Maybe<Scalars['Int']['output']>
  partnerCompany: PartnerCompany
}

export type LineItem = {
  __typename?: 'LineItem'
  net: Scalars['Int']['output']
  quantity: Scalars['Int']['output']
  ticketCategoryId: Scalars['String']['output']
}

export enum LineItemTypeEnum {
  Goods = 'goods',
  Voucher = 'voucher',
}

export enum LocaleEnum {
  De = 'de',
  En = 'en',
}

export type Location = Node & {
  __typename?: 'Location'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  mapsUrl?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  zipcode?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of ManuallyMarkAsSent */
export type ManuallyMarkAsSentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  promoCodeId: Scalars['ID']['input']
}

/** Autogenerated return type of ManuallyMarkAsSent. */
export type ManuallyMarkAsSentPayload = {
  __typename?: 'ManuallyMarkAsSentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  promoCode?: Maybe<PromoCode>
}

/** Autogenerated input type of ManuallyMarkAsUnsent */
export type ManuallyMarkAsUnsentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  promoCodeId: Scalars['ID']['input']
}

/** Autogenerated return type of ManuallyMarkAsUnsent. */
export type ManuallyMarkAsUnsentPayload = {
  __typename?: 'ManuallyMarkAsUnsentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  promoCode?: Maybe<PromoCode>
}

export enum MerchSizeEnum {
  L = 'L',
  M = 'M',
  S = 'S',
  Xl = 'XL',
  Xs = 'XS',
  Xxl = 'XXL',
  Xxxl = 'XXXL',
  Xxxxl = 'XXXXL',
}

export type Mutation = {
  __typename?: 'Mutation'
  acceptAttendee?: Maybe<AcceptPayload>
  acceptTicketRequest?: Maybe<AcceptTicketRequestPayload>
  activateTicket?: Maybe<ActivateTicketPayload>
  addAttendeesByTicketIdentifier?: Maybe<AddAttendeesByTicketIdentifierPayload>
  closeAttendeeSelection?: Maybe<CloseAttendeeSelectionPayload>
  createAcknowledgement?: Maybe<CreateAcknowledgementPayload>
  createContact?: Maybe<CreateContactPayload>
  createContactTransaction?: Maybe<CreateContactTransactionPayload>
  createDirectTicket?: Maybe<CreateDirectTicketPayload>
  createDueDateCompletion?: Maybe<CreatePayload>
  createEventApplications?: Maybe<ApplyPayload>
  createEventSpeaker?: Maybe<CreateEventSpeakerPayload>
  createIndividualInvitees?: Maybe<CreateIndividualInviteesPayload>
  createInternalAccessCheck?: Maybe<CreateInternalAccessCheckPayload>
  createLead?: Maybe<CreateLeadPayload>
  createOrder?: Maybe<CreateOrderPayload>
  createPartnerCompanyWithHubspotId?: Maybe<CreatePartnerCompanyWithHubspotIdPayload>
  createProspectItem?: Maybe<CreateProspectItemPayload>
  createProspectList?: Maybe<CreateProspectListPayload>
  createReviewsManager?: Maybe<CreateReviewsManagerPayload>
  createSpeaker?: Maybe<CreateSpeakerPayload>
  createUser?: Maybe<CreateUserPayload>
  deactivateTicket?: Maybe<DeactivateTicketPayload>
  deleteAcknowledgementUpload?: Maybe<DeleteAcknowledgementUploadPayload>
  deleteDueDateCompletion?: Maybe<DeletePayload>
  deleteEventSpeaker?: Maybe<DeleteEventSpeakerPayload>
  deleteIndividualInvitees?: Maybe<DeleteIndividualInviteesPayload>
  deleteProspectItem?: Maybe<DeleteProspectItemPayload>
  deleteProspectList?: Maybe<DeleteProspectListPayload>
  deleteTicket?: Maybe<DeleteTicketPayload>
  deleteUnsentInvitees?: Maybe<DeleteUnsentInviteesPayload>
  denyAttendee?: Maybe<DenyPayload>
  denyTicketRequest?: Maybe<DenyTicketRequestPayload>
  disconnectOauthProvider?: Maybe<DisconnectPayload>
  editEmailTemplate?: Maybe<EditEmailTemplatePayload>
  exportLeads?: Maybe<ExportLeadsPayload>
  importInvitees?: Maybe<ImportInviteesPayload>
  inboundContact?: Maybe<CreateInboundContactPayload>
  manuallyMarkAsSent?: Maybe<ManuallyMarkAsSentPayload>
  manuallyMarkAsUnsent?: Maybe<ManuallyMarkAsUnsentPayload>
  placeCheckoutOrder?: Maybe<PlaceOrderPayload>
  resendConfirmationInstructions?: Maybe<ResendConfirmationInstructionsPayload>
  resendTicketMail?: Maybe<ResendTicketMailPayload>
  retractVolunteer: Volunteer
  sendIndividualInvitations?: Maybe<SendIndividualInvitationsPayload>
  sendInvitations?: Maybe<SendInvitationsPayload>
  sendProspectListInvitations?: Maybe<SendProspectListInvitationsPayload>
  sendTestEmail?: Maybe<SendTestEmailPayload>
  sendTestProspectInvitation?: Maybe<SendTestInvitationPayload>
  setPaymentInfo?: Maybe<SetPaymentInfoPayload>
  submitProspectList?: Maybe<SubmitProspectListPayload>
  submitVolunteer: Volunteer
  syncLeads?: Maybe<UpsertLeadsPayload>
  updateAcknowledgement?: Maybe<UpdateAcknowledgementPayload>
  updateAttendeesStateFromCsv?: Maybe<UpdateAttendeesStateFromCsvPayload>
  updateBillingAddress?: Maybe<UpdateBillingAddressPayload>
  updateBooth?: Maybe<UpdateBoothPayload>
  updateBoothExtras?: Maybe<UpdateExtrasPayload>
  updateCheckoutOrder?: Maybe<UpdateOrderPayload>
  updateContact?: Maybe<UpdateContactPayload>
  updateDirectTicket?: Maybe<UpdateDirectTicketPayload>
  updateEvent?: Maybe<UpdateEventPayload>
  updateEventStarrings?: Maybe<UpdateEventStarringsPayload>
  updateInterests?: Maybe<UpdateInterestsPayload>
  updateLead?: Maybe<UpdateLeadPayload>
  updateMe?: Maybe<UpdateMePayload>
  updatePartnerCompany?: Maybe<UpdatePartnerCompanyPayload>
  updateProspectList?: Maybe<UpdateProspectListPayload>
  updateSpeaker?: Maybe<UpdateSpeakerPayload>
  updateTicket?: Maybe<UpdateTicketPayload>
  updateUser?: Maybe<UpdateUserPayload>
  updateUserRoles?: Maybe<UpdateUserRolesPayload>
  uploadInvitees?: Maybe<UploadInviteesPayload>
  upsertLeads?: Maybe<UpsertLeadsPayload>
  upsertVolunteer: Volunteer
}

export type MutationAcceptAttendeeArgs = {
  input: AcceptInput
}

export type MutationAcceptTicketRequestArgs = {
  input: AcceptTicketRequestInput
}

export type MutationActivateTicketArgs = {
  input: ActivateTicketInput
}

export type MutationAddAttendeesByTicketIdentifierArgs = {
  input: AddAttendeesByTicketIdentifierInput
}

export type MutationCloseAttendeeSelectionArgs = {
  input: CloseAttendeeSelectionInput
}

export type MutationCreateAcknowledgementArgs = {
  input: CreateAcknowledgementInput
}

export type MutationCreateContactArgs = {
  input: CreateContactInput
}

export type MutationCreateContactTransactionArgs = {
  input: CreateContactTransactionInput
}

export type MutationCreateDirectTicketArgs = {
  input: CreateDirectTicketInput
}

export type MutationCreateDueDateCompletionArgs = {
  input: CreateInput
}

export type MutationCreateEventApplicationsArgs = {
  input: ApplyInput
}

export type MutationCreateEventSpeakerArgs = {
  input: CreateEventSpeakerInput
}

export type MutationCreateIndividualInviteesArgs = {
  input: CreateIndividualInviteesInput
}

export type MutationCreateInternalAccessCheckArgs = {
  input: CreateInternalAccessCheckInput
}

export type MutationCreateLeadArgs = {
  input: CreateLeadInput
}

export type MutationCreateOrderArgs = {
  input: CreateOrderInput
}

export type MutationCreatePartnerCompanyWithHubspotIdArgs = {
  input: CreatePartnerCompanyWithHubspotIdInput
}

export type MutationCreateProspectItemArgs = {
  input: CreateProspectItemInput
}

export type MutationCreateProspectListArgs = {
  input: CreateProspectListInput
}

export type MutationCreateReviewsManagerArgs = {
  input: CreateReviewsManagerInput
}

export type MutationCreateSpeakerArgs = {
  input: CreateSpeakerInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeactivateTicketArgs = {
  input: DeactivateTicketInput
}

export type MutationDeleteAcknowledgementUploadArgs = {
  input: DeleteAcknowledgementUploadInput
}

export type MutationDeleteDueDateCompletionArgs = {
  input: DeleteInput
}

export type MutationDeleteEventSpeakerArgs = {
  input: DeleteEventSpeakerInput
}

export type MutationDeleteIndividualInviteesArgs = {
  input: DeleteIndividualInviteesInput
}

export type MutationDeleteProspectItemArgs = {
  input: DeleteProspectItemInput
}

export type MutationDeleteProspectListArgs = {
  input: DeleteProspectListInput
}

export type MutationDeleteTicketArgs = {
  input: DeleteTicketInput
}

export type MutationDeleteUnsentInviteesArgs = {
  input: DeleteUnsentInviteesInput
}

export type MutationDenyAttendeeArgs = {
  input: DenyInput
}

export type MutationDenyTicketRequestArgs = {
  input: DenyTicketRequestInput
}

export type MutationDisconnectOauthProviderArgs = {
  input: DisconnectInput
}

export type MutationEditEmailTemplateArgs = {
  input: EditEmailTemplateInput
}

export type MutationExportLeadsArgs = {
  input: ExportLeadsInput
}

export type MutationImportInviteesArgs = {
  input: ImportInviteesInput
}

export type MutationInboundContactArgs = {
  input: CreateInboundContactInput
}

export type MutationManuallyMarkAsSentArgs = {
  input: ManuallyMarkAsSentInput
}

export type MutationManuallyMarkAsUnsentArgs = {
  input: ManuallyMarkAsUnsentInput
}

export type MutationPlaceCheckoutOrderArgs = {
  input: PlaceOrderInput
}

export type MutationResendConfirmationInstructionsArgs = {
  input: ResendConfirmationInstructionsInput
}

export type MutationResendTicketMailArgs = {
  input: ResendTicketMailInput
}

export type MutationRetractVolunteerArgs = {
  input: RetractVolunteerInput
}

export type MutationSendIndividualInvitationsArgs = {
  input: SendIndividualInvitationsInput
}

export type MutationSendInvitationsArgs = {
  input: SendInvitationsInput
}

export type MutationSendProspectListInvitationsArgs = {
  input: SendProspectListInvitationsInput
}

export type MutationSendTestEmailArgs = {
  input: SendTestEmailInput
}

export type MutationSendTestProspectInvitationArgs = {
  input: SendTestInvitationInput
}

export type MutationSetPaymentInfoArgs = {
  input: SetPaymentInfoInput
}

export type MutationSubmitProspectListArgs = {
  input: SubmitProspectListInput
}

export type MutationSubmitVolunteerArgs = {
  input: SubmitVolunteerInput
}

export type MutationSyncLeadsArgs = {
  input: UpsertLeadsInput
}

export type MutationUpdateAcknowledgementArgs = {
  input: UpdateAcknowledgementInput
}

export type MutationUpdateAttendeesStateFromCsvArgs = {
  input: UpdateAttendeesStateFromCsvInput
}

export type MutationUpdateBillingAddressArgs = {
  input: UpdateBillingAddressInput
}

export type MutationUpdateBoothArgs = {
  input: UpdateBoothInput
}

export type MutationUpdateBoothExtrasArgs = {
  input: UpdateExtrasInput
}

export type MutationUpdateCheckoutOrderArgs = {
  input: UpdateOrderInput
}

export type MutationUpdateContactArgs = {
  input: UpdateContactInput
}

export type MutationUpdateDirectTicketArgs = {
  input: UpdateDirectTicketInput
}

export type MutationUpdateEventArgs = {
  input: UpdateEventInput
}

export type MutationUpdateEventStarringsArgs = {
  input: UpdateEventStarringsInput
}

export type MutationUpdateInterestsArgs = {
  input: UpdateInterestsInput
}

export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput
}

export type MutationUpdateMeArgs = {
  input: UpdateMeInput
}

export type MutationUpdatePartnerCompanyArgs = {
  input: UpdatePartnerCompanyInput
}

export type MutationUpdateProspectListArgs = {
  input: UpdateProspectListInput
}

export type MutationUpdateSpeakerArgs = {
  input: UpdateSpeakerInput
}

export type MutationUpdateTicketArgs = {
  input: UpdateTicketInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserRolesArgs = {
  input: UpdateUserRolesInput
}

export type MutationUploadInviteesArgs = {
  input: UploadInviteesInput
}

export type MutationUpsertLeadsArgs = {
  input: UpsertLeadsInput
}

export type MutationUpsertVolunteerArgs = {
  input: UpsertVolunteerInput
}

export type News = {
  __typename?: 'News'
  bodyDe?: Maybe<Scalars['String']['output']>
  bodyEn?: Maybe<Scalars['String']['output']>
  bodyHtmlDe?: Maybe<Scalars['String']['output']>
  bodyHtmlEn?: Maybe<Scalars['String']['output']>
  ctaButtonLink?: Maybe<Scalars['String']['output']>
  ctaButtonTextDe?: Maybe<Scalars['String']['output']>
  ctaButtonTextEn?: Maybe<Scalars['String']['output']>
  force: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  imageUrl?: Maybe<Scalars['String']['output']>
  tags?: Maybe<Array<Scalars['String']['output']>>
  titleDe: Scalars['String']['output']
  titleEn: Scalars['String']['output']
}

/** The connection type for News. */
export type NewsConnection = {
  __typename?: 'NewsConnection'
  /** A list of edges. */
  edges: Array<NewsEdge>
  /** A list of nodes. */
  nodes: Array<News>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type NewsEdge = {
  __typename?: 'NewsEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<News>
}

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output']
}

export enum OauthProviderEnum {
  GoogleOauth2 = 'google_oauth2',
  Linkedin = 'linkedin',
}

export type OffsetPaginationInfo = {
  __typename?: 'OffsetPaginationInfo'
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  limit: Scalars['Int']['output']
  offset: Scalars['Int']['output']
  pagesCount: Scalars['Int']['output']
  /** Total items count */
  totalCount: Scalars['Int']['output']
}

export type Onsite = {
  __typename?: 'Onsite'
  badgeColor?: Maybe<Scalars['String']['output']>
  postPayLimit: Scalars['Int']['output']
  primaryClassification?: Maybe<FieldCategoryEnum>
  wristbandColor?: Maybe<Scalars['String']['output']>
}

export type Order = Node & {
  __typename?: 'Order'
  /** Status of the order. requested = only our system / link sent, appointed = sent to payone */
  aasmState: OrderAasmStateEnum
  /** Additional Address */
  additionalAddress?: Maybe<Scalars['String']['output']>
  /** Checkout Token */
  afterPaymentToken?: Maybe<Scalars['String']['output']>
  /** @deprecated Please use amount_in_cents_gross */
  amountInCents: Scalars['Int']['output']
  /** Gross amount in cents */
  amountInCentsGross: Scalars['Int']['output']
  /** Net amount in cents */
  amountInCentsNet: Scalars['Int']['output']
  /** @deprecated Please use amount_in_euros_gross */
  amountInEuros: Scalars['Float']['output']
  /** Gross amount in euros */
  amountInEurosGross: Scalars['Float']['output']
  /** Net amount in euros */
  amountInEurosNet: Scalars['Float']['output']
  /** Appointed At */
  appointedAt: Scalars['ISO8601DateTime']['output']
  /** Orders badge transactions */
  badgeTransactions?: Maybe<BadgeTransactionConnection>
  /** Canceled At */
  canceledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** City */
  city?: Maybe<Scalars['String']['output']>
  /** Company name */
  company?: Maybe<Scalars['String']['output']>
  /** Country */
  country?: Maybe<Scalars['String']['output']>
  /** Created At */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Customer reference */
  customerReference?: Maybe<Scalars['String']['output']>
  /** E-Mail */
  email?: Maybe<Scalars['String']['output']>
  /** Event the order is for */
  event?: Maybe<Event>
  /** First name */
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** Invoice Number */
  invoiceNumber?: Maybe<Scalars['String']['output']>
  invoices: Array<Invoice>
  /** Last Name */
  lastName?: Maybe<Scalars['String']['output']>
  /** House number */
  number?: Maybe<Scalars['String']['output']>
  /** Order paid? */
  paid?: Maybe<Scalars['Boolean']['output']>
  /** Paid At */
  paidAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Payment method */
  paymentMethod?: Maybe<Scalars['String']['output']>
  /** PayOne ID */
  payoneId?: Maybe<Scalars['String']['output']>
  /** Period of performance ends at */
  periodOfPerformanceEndsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Period of performance starts at */
  periodOfPerformanceStartsAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** ??? */
  reference?: Maybe<Scalars['String']['output']>
  /** Salutation */
  salutation?: Maybe<Scalars['String']['output']>
  /** Street */
  street?: Maybe<Scalars['String']['output']>
  /** Title */
  title?: Maybe<Scalars['String']['output']>
  /** Order type */
  type?: Maybe<OrderTypeEnum>
  /** Updated At */
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** VAT */
  vat?: Maybe<Scalars['Int']['output']>
  /** VAT Number */
  vatNumber?: Maybe<Scalars['String']['output']>
  /** Postal code */
  zip?: Maybe<Scalars['Int']['output']>
}

export type OrderBadgeTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export enum OrderAasmStateEnum {
  Appointed = 'appointed',
  Canceled = 'canceled',
  CheckedOut = 'checked_out',
  CheckoutFailed = 'checkout_failed',
  Paid = 'paid',
  Requested = 'requested',
}

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: 'OrderConnection'
  /** A list of edges. */
  edges: Array<OrderEdge>
  /** A list of nodes. */
  nodes: Array<Order>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

export enum OrderDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

/** An edge in a connection. */
export type OrderEdge = {
  __typename?: 'OrderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Order>
}

export enum OrderTypeEnum {
  BadgeOrder = 'BadgeOrder',
  JobOrder = 'JobOrder',
  ReportOrder = 'ReportOrder',
  TicketOrder = 'TicketOrder',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PageVisit = {
  __typename?: 'PageVisit'
  address?: Maybe<Scalars['String']['output']>
  alexaRank?: Maybe<Scalars['Int']['output']>
  categorySlugs: Array<Scalars['String']['output']>
  city?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyWebsite?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  email?: Maybe<Scalars['String']['output']>
  /** @deprecated Use emploees_from and employees_to instead */
  employees?: Maybe<Scalars['String']['output']>
  employeesFrom?: Maybe<Scalars['String']['output']>
  employeesTo?: Maybe<Scalars['String']['output']>
  /** @deprecated Use financial_report_from, financial_report_to and financial_report_currency instead */
  financialReport?: Maybe<Scalars['String']['output']>
  financialReportCurrency?: Maybe<Scalars['String']['output']>
  financialReportFrom?: Maybe<Scalars['String']['output']>
  financialReportTo?: Maybe<Scalars['String']['output']>
  foundedYear?: Maybe<Scalars['Int']['output']>
  id: Scalars['ID']['output']
  identifier?: Maybe<Scalars['String']['output']>
  linkedinIndustryCategory?: Maybe<Scalars['String']['output']>
  linkedinIndustryCode?: Maybe<Scalars['String']['output']>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  productSlugs: Array<Scalars['String']['output']>
  project?: Maybe<Scalars['String']['output']>
  recurring?: Maybe<Scalars['Boolean']['output']>
  sector?: Maybe<Scalars['String']['output']>
  subjectId?: Maybe<Scalars['String']['output']>
  subjectType?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
  zipCode?: Maybe<Scalars['String']['output']>
}

export type PageVisitCompanyWebsiteArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PageVisitEmployeesArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PageVisitFinancialReportArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

export type PageVisitLinkedinIndustryCodeArgs = {
  formatted?: InputMaybe<Scalars['Boolean']['input']>
}

/** The connection type for PageVisit. */
export type PageVisitConnection = {
  __typename?: 'PageVisitConnection'
  /** A list of edges. */
  edges: Array<PageVisitEdge>
  /** A list of nodes. */
  nodes: Array<PageVisit>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type PageVisitEdge = {
  __typename?: 'PageVisitEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<PageVisit>
}

export type PartnerCompany = Node & {
  __typename?: 'PartnerCompany'
  billingAddress?: Maybe<BillingAddress>
  booths: Array<Booth>
  city?: Maybe<Scalars['String']['output']>
  contacts: Array<Contact>
  countryCode?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  displayName?: Maybe<Scalars['String']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  familyMember: Scalars['Boolean']['output']
  foodTypes: Array<BoothFoodTypeEnum>
  guidedTours?: Maybe<Array<Event>>
  hasChildEvents: Scalars['Boolean']['output']
  hasFoodBooths: Scalars['Boolean']['output']
  hasMasterclass?: Maybe<Scalars['Boolean']['output']>
  hasQuotas: Scalars['Boolean']['output']
  hasSpeaker?: Maybe<Scalars['Boolean']['output']>
  hasStageSlot?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  instagram?: Maybe<Scalars['String']['output']>
  interests?: Maybe<Array<Interest>>
  leadGenLimitConfigs: Array<LeadGenLimitConfig>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  logoUrl?: Maybe<Scalars['String']['output']>
  masterclasses?: Maybe<Array<Event>>
  name?: Maybe<Scalars['String']['output']>
  omrJobsUrl?: Maybe<Scalars['String']['output']>
  publicEmail?: Maybe<Scalars['String']['output']>
  sideEvents?: Maybe<Array<Event>>
  slug?: Maybe<Scalars['String']['output']>
  stageSlots?: Maybe<Array<Event>>
  street?: Maybe<Scalars['String']['output']>
  tagList?: Maybe<Array<TagListTypeEnum>>
  twitter?: Maybe<Scalars['String']['output']>
  webLogoUrl?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
  youtube?: Maybe<Scalars['String']['output']>
  zipcode?: Maybe<Scalars['String']['output']>
}

/** Attributes for creating or updating a partner_company */
export type PartnerCompanyAttributes = {
  /** Description DE */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** Description EN */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  /** Company display name */
  displayName?: InputMaybe<Scalars['String']['input']>
  /** Facebook */
  facebook?: InputMaybe<Scalars['String']['input']>
  /** Instagram */
  instagram?: InputMaybe<Scalars['String']['input']>
  /** Interests */
  interests?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Linkedin */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  logo?: InputMaybe<Scalars['Upload']['input']>
  /** Company name */
  name?: InputMaybe<Scalars['String']['input']>
  /** OMRJobs URL */
  omrJobsUrl?: InputMaybe<Scalars['String']['input']>
  /** Twitter */
  twitter?: InputMaybe<Scalars['String']['input']>
  webLogo?: InputMaybe<Scalars['Upload']['input']>
  /** Website */
  website?: InputMaybe<Scalars['String']['input']>
  /** Youtube */
  youtube?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for PartnerCompany. */
export type PartnerCompanyConnection = {
  __typename?: 'PartnerCompanyConnection'
  /** A list of edges. */
  edges: Array<PartnerCompanyEdge>
  /** A list of nodes. */
  nodes: Array<PartnerCompany>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type PartnerCompanyEdge = {
  __typename?: 'PartnerCompanyEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<PartnerCompany>
}

export type PartnerCompanyMatch = {
  __typename?: 'PartnerCompanyMatch'
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  url: Scalars['String']['output']
  webLogoUrl: Scalars['String']['output']
}

export type PartnerCompanyMatchPaginatedCollection = {
  __typename?: 'PartnerCompanyMatchPaginatedCollection'
  nodes: Array<PartnerCompanyMatch>
  pageInfo: OffsetPaginationInfo
}

export type PaymentInfo = {
  __typename?: 'PaymentInfo'
  additionalAddress?: Maybe<Scalars['String']['output']>
  address: Scalars['String']['output']
  business: Scalars['Boolean']['output']
  city: Scalars['String']['output']
  company?: Maybe<Scalars['String']['output']>
  country: Scalars['String']['output']
  customerReference?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  salutation: Scalars['String']['output']
  vatNumber?: Maybe<Scalars['String']['output']>
  zip: Scalars['String']['output']
}

export enum PaymentMethodEnum {
  Amex = 'amex',
  Debit = 'debit',
  Invoice = 'invoice',
  Mastercard = 'mastercard',
  Paypal = 'paypal',
  Visa = 'visa',
}

/** Attributes for placing orders */
export type PlaceOrderAttributes = {
  bankAccount?: InputMaybe<Scalars['String']['input']>
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>
  bankCode?: InputMaybe<Scalars['String']['input']>
  bankCountry?: InputMaybe<Scalars['String']['input']>
  bic?: InputMaybe<Scalars['String']['input']>
  cardExpireDate?: InputMaybe<Scalars['String']['input']>
  cardHolder?: InputMaybe<Scalars['String']['input']>
  cardType?: InputMaybe<Scalars['String']['input']>
  clearingType?: InputMaybe<Scalars['String']['input']>
  iban?: InputMaybe<Scalars['String']['input']>
  method: PaymentMethodEnum
  pseudoCardpan?: InputMaybe<Scalars['String']['input']>
  truncatedCardpan?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of PlaceOrder */
export type PlaceOrderInput = {
  afterPaymentToken: Scalars['String']['input']
  attributes: PlaceOrderAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of PlaceOrder. */
export type PlaceOrderPayload = {
  __typename?: 'PlaceOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  order?: Maybe<CheckoutOrder>
  redirectUrl?: Maybe<Scalars['String']['output']>
}

export type PodcastMatch = {
  __typename?: 'PodcastMatch'
  authors: Scalars['String']['output']
  coverImage?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  episodesCount: Scalars['Int']['output']
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  keywords: Array<Scalars['String']['output']>
  lastEpisodePublicationDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  publicationType: Scalars['String']['output']
  publishedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  slug: Scalars['String']['output']
  subtitle?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type PodcastMatchPaginatedCollection = {
  __typename?: 'PodcastMatchPaginatedCollection'
  nodes: Array<PodcastMatch>
  pageInfo: OffsetPaginationInfo
}

export type Product = {
  __typename?: 'Product'
  attachmentUrl?: Maybe<Scalars['String']['output']>
  commentRequired: Scalars['Boolean']['output']
  deliveryFee?: Maybe<Scalars['Float']['output']>
  description: Scalars['String']['output']
  fileUploadRequired: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  maximum?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  productCategory?: Maybe<ProductCategory>
  variants: Array<Variant>
}

export type ProductCategory = {
  __typename?: 'ProductCategory'
  cancellationDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  expiresAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  position: Scalars['Int']['output']
}

export type ProductMatch = {
  __typename?: 'ProductMatch'
  averageRecommendationRating?: Maybe<Scalars['Float']['output']>
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  logo?: Maybe<ReviewsAsset>
  reviewCount: Scalars['Int']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ProductMatchPaginatedCollection = {
  __typename?: 'ProductMatchPaginatedCollection'
  nodes: Array<ProductMatch>
  pageInfo: OffsetPaginationInfo
}

export enum ProfileScopeEnum {
  Basic = 'basic',
  Detailed = 'detailed',
  NetworkingTerms = 'networking_terms',
  ReviewsTerms = 'reviews_terms',
}

export type PromoCode = Node & {
  __typename?: 'PromoCode'
  code: Scalars['String']['output']
  companyName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  manuallyMarkedAsSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  ticketCount: Scalars['Int']['output']
  tickets?: Maybe<Array<Ticket>>
  timesUsableRemaining: Scalars['Int']['output']
  usable: Scalars['Boolean']['output']
  userName?: Maybe<Scalars['String']['output']>
}

export type PromoCodeDiscount = {
  __typename?: 'PromoCodeDiscount'
  code?: Maybe<Scalars['String']['output']>
  discountAmount?: Maybe<Scalars['Int']['output']>
  ticketCategoryId?: Maybe<Scalars['String']['output']>
  timesApplied?: Maybe<Scalars['Int']['output']>
}

export type Prospect = {
  __typename?: 'Prospect'
  _score?: Maybe<Scalars['Float']['output']>
  available: Scalars['Boolean']['output']
  companies: Array<ProspectCompany>
  highlight?: Maybe<SearchResultHighlight>
  id: Scalars['ID']['output']
  visitedEvents: Array<VisitedEvent>
}

export type ProspectCompany = {
  __typename?: 'ProspectCompany'
  department?: Maybe<CompanyDepartmentEnum>
  eventSlug: Scalars['String']['output']
  field?: Maybe<Scalars['ID']['output']>
  industry?: Maybe<Scalars['ID']['output']>
  jobLevel?: Maybe<JobLevelEnum>
  name?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  size?: Maybe<Scalars['ID']['output']>
}

export type ProspectList = Node & {
  __typename?: 'ProspectList'
  contactEmail?: Maybe<Scalars['String']['output']>
  contactName?: Maybe<Scalars['String']['output']>
  contactReason?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  event: Event
  id: Scalars['ID']['output']
  partnerCompany: PartnerCompany
  prospects: Array<Prospect>
  submittedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  title: Scalars['String']['output']
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** Attributes for creating or updating prospect list */
export type ProspectListAttributes = {
  contactEmail: Scalars['String']['input']
  contactName: Scalars['String']['input']
  contactReason?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
}

/** The connection type for ProspectList. */
export type ProspectListConnection = {
  __typename?: 'ProspectListConnection'
  /** A list of edges. */
  edges: Array<ProspectListEdge>
  /** A list of nodes. */
  nodes: Array<ProspectList>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type ProspectListEdge = {
  __typename?: 'ProspectListEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<ProspectList>
}

export type ProspectsCount = {
  __typename?: 'ProspectsCount'
  relation: Scalars['String']['output']
  total: Scalars['Int']['output']
}

export type ProspectsFilter = {
  __typename?: 'ProspectsFilter'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ProspectsSearchResult = {
  __typename?: 'ProspectsSearchResult'
  companyDepartmentFacets: Array<Facet>
  companyFieldFacets: Array<Facet>
  companyIndustryFacets: Array<Facet>
  companyJobLevelFacets: Array<Facet>
  companySizeFacets: Array<Facet>
  perPage: Scalars['Int']['output']
  prospects: Array<Prospect>
  prospectsCount: ProspectsCount
  visitedEventsFilter: Array<ProspectsFilter>
}

export type PublishedBooth = {
  __typename?: 'PublishedBooth'
  boost: Scalars['Int']['output']
  boothNumber: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  foodTypes?: Maybe<Array<BoothFoodTypeEnum>>
  id: Scalars['ID']['output']
  partnerCompany: PublishedPartnerCompany
  premium: Scalars['Boolean']['output']
  priceIndicator?: Maybe<Scalars['Int']['output']>
  showBoothNumberAsMapLabel: Scalars['Boolean']['output']
  showOnMap: Scalars['Boolean']['output']
  title?: Maybe<Scalars['String']['output']>
  type: BoothTypeEnum
}

export type PublishedBoothPaginatedCollection = {
  __typename?: 'PublishedBoothPaginatedCollection'
  nodes: Array<PublishedBooth>
  pageInfo: OffsetPaginationInfo
}

export type PublishedEvent = {
  __typename?: 'PublishedEvent'
  applicationStop: Scalars['Boolean']['output']
  description?: Maybe<Scalars['String']['output']>
  endsAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['ID']['output']
  idHash: Scalars['String']['output']
  interests: Array<Interest>
  language?: Maybe<LanguageEnum>
  name: Scalars['String']['output']
  partnerCompanies: Array<PublishedPartnerCompany>
  slug: Scalars['String']['output']
  speakers: Array<PublishedSpeaker>
  stage: Stage
  stageId: Scalars['ID']['output']
  startsAt: Scalars['ISO8601DateTime']['output']
  type: EventTypeEnum
}

export type PublishedPartnerCompany = {
  __typename?: 'PublishedPartnerCompany'
  boothNumbers: Array<Scalars['String']['output']>
  contactId?: Maybe<Scalars['ID']['output']>
  description: Scalars['String']['output']
  displayName: Scalars['String']['output']
  eventIds: Array<Scalars['ID']['output']>
  facebook?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instagram?: Maybe<Scalars['String']['output']>
  interests: Array<Interest>
  linkedIn?: Maybe<Scalars['String']['output']>
  omrJobsUrl?: Maybe<Scalars['String']['output']>
  premium?: Maybe<Scalars['Boolean']['output']>
  slug: Scalars['String']['output']
  twitter?: Maybe<Scalars['String']['output']>
  webLogoUrl: Scalars['String']['output']
  website?: Maybe<Scalars['String']['output']>
  youtube?: Maybe<Scalars['String']['output']>
}

export type PublishedPartnerCompanyBoothNumbersArgs = {
  rootEventSlug: Scalars['String']['input']
}

export type PublishedPartnerCompanyEventIdsArgs = {
  rootEventSlug: Scalars['String']['input']
}

export type PublishedPartnerCompanyPaginatedCollection = {
  __typename?: 'PublishedPartnerCompanyPaginatedCollection'
  nodes: Array<PublishedPartnerCompany>
  pageInfo: OffsetPaginationInfo
}

export type PublishedQuery = {
  __typename?: 'PublishedQuery'
  /**
   * Published booths
   * @deprecated deprecated use boothsPaginated instead
   */
  booths: Array<PublishedBooth>
  /** Published booths */
  boothsPaginated: PublishedBoothPaginatedCollection
  eventsForApplication: Array<EventsForApplication>
  /** JSON-LD for the published event */
  jsonLd: JsonLd
  /**
   * Published exhibitors
   * @deprecated deprecated use partnerCompaniesPaginated instead
   */
  partnerCompanies: Array<PublishedPartnerCompany>
  /** Published exhibitors */
  partnerCompaniesPaginated: PublishedPartnerCompanyPaginatedCollection
  /** Published partner company by ID */
  partnerCompany: PublishedPartnerCompany
  /** Published event */
  publishedEvent: PublishedEvent
  /** Published events */
  publishedEvents: Array<PublishedEvent>
  /** Published speaker by ID */
  speaker: PublishedSpeaker
  /** Published speakers and artists by event slug */
  speakersAndArtists: PublishedSpeakerPaginatedCollection
  /** Timetable for event */
  timetable: Timetable
}

export type PublishedQueryBoothsArgs = {
  rootEventSlug: Scalars['String']['input']
  types?: InputMaybe<Array<BoothTypeEnum>>
}

export type PublishedQueryBoothsPaginatedArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  rootEventSlug: Scalars['String']['input']
  types?: InputMaybe<Array<BoothTypeEnum>>
}

export type PublishedQueryEventsForApplicationArgs = {
  ticketId: Scalars['ID']['input']
}

export type PublishedQueryJsonLdArgs = {
  eventSlug: Scalars['String']['input']
}

export type PublishedQueryPartnerCompaniesArgs = {
  rootEventSlug: Scalars['String']['input']
}

export type PublishedQueryPartnerCompaniesPaginatedArgs = {
  days?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>
  eventType?: InputMaybe<EventTypeEnum>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  limitToList?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  premium?: InputMaybe<Scalars['Boolean']['input']>
  rootEventSlug: Scalars['String']['input']
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortByList?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryPartnerCompanyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryPublishedEventArgs = {
  eventSlug: Scalars['String']['input']
  rootEventSlug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryPublishedEventsArgs = {
  eventIds: Array<Scalars['ID']['input']>
}

export type PublishedQuerySpeakerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>
  rootEventSlug?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQuerySpeakersAndArtistsArgs = {
  days?: InputMaybe<Array<Scalars['ISO8601DateTime']['input']>>
  eventType?: InputMaybe<EventTypeEnum>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  limit?: InputMaybe<Scalars['Int']['input']>
  limitToList?: InputMaybe<Scalars['String']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<SpeakerRoleEnum>
  rootEventSlug: Scalars['String']['input']
  searchTerm?: InputMaybe<Scalars['String']['input']>
  sortByList?: InputMaybe<Scalars['String']['input']>
  stageSlug?: InputMaybe<Scalars['String']['input']>
}

export type PublishedQueryTimetableArgs = {
  eventSlug: Scalars['String']['input']
  stageNames?: InputMaybe<Array<Scalars['String']['input']>>
  types?: InputMaybe<Array<EventTypeEnum>>
}

export type PublishedSpeaker = {
  __typename?: 'PublishedSpeaker'
  createdAt: Scalars['ISO8601DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  eventIds: Array<Scalars['ID']['output']>
  facebookUrl?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instagramUrl?: Maybe<Scalars['String']['output']>
  interests: Array<Interest>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  partnerCompanyName?: Maybe<Scalars['String']['output']>
  photoUrl: Scalars['String']['output']
  /** @deprecated position is deprecated, use title instead */
  position?: Maybe<Scalars['String']['output']>
  roles?: Maybe<Array<SpeakerRoleEnum>>
  slug: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  twitterUrl?: Maybe<Scalars['String']['output']>
  websiteUrl?: Maybe<Scalars['String']['output']>
  youtubeUrl?: Maybe<Scalars['String']['output']>
}

export type PublishedSpeakerPaginatedCollection = {
  __typename?: 'PublishedSpeakerPaginatedCollection'
  nodes: Array<PublishedSpeaker>
  pageInfo: OffsetPaginationInfo
}

export type Query = {
  __typename?: 'Query'
  /** Find attendees by event_id */
  attendees: AttendeeConnection
  /** Booths */
  booths: BoothConnection
  checkout: CheckoutQuery
  /** Content streams */
  contentStreams: ContentStreamConnection
  /** CSV Preview Data */
  csvPreviewData?: Maybe<Array<CsvPreviewData>>
  currentTime: Scalars['ISO8601DateTime']['output']
  dashboardTickets: Array<Ticket>
  /** Document Acknowledgements */
  docAcknowledgements: DocAcknowledgementConnection
  /** Documents */
  documents: DocumentConnection
  /** DueDates */
  dueDateItems: Array<DueDateItem>
  /** event */
  event: Event
  /** event */
  eventById: Event
  eventNotifications: Array<EventNotification>
  /** events */
  events: EventConnectionWithTotalCount
  /** geojson_maps */
  geojsonMaps: GeojsonMaps
  /** Interests */
  interests: InterestConnection
  invitationQuota: InvitationQuota
  /** Invitation Quotas */
  invitationQuotas: InvitationQuotaConnection
  /** Invitees */
  invitees: InviteePaginatedCollection
  jwtMasterclass: JwtMasterclass
  /** leads */
  leads: LeadConnection
  /** your own user */
  me?: Maybe<User>
  /** My orders */
  myOrders: OrderConnection
  /** News */
  news: NewsConnection
  /** Fetches an object given its ID. */
  node?: Maybe<Node>
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>
  /** orders */
  orders: OrderConnection
  /** PageVisits */
  pageVisits?: Maybe<PageVisitConnection>
  /** partner_companies */
  partnerCompanies: PartnerCompanyConnection
  /** ProspectLists */
  prospectLists?: Maybe<ProspectListConnection>
  prospects?: Maybe<ProspectsSearchResult>
  published: PublishedQuery
  search: SearchResult
  /** speakers */
  speakers: SpeakerConnection
  /** stage */
  stage?: Maybe<Stage>
  /** ticket */
  ticket?: Maybe<Ticket>
  ticketShop: TicketShopQuery
  /** tickets */
  tickets: TicketConnection
  /** user */
  user?: Maybe<User>
  userPropertiesPreferences: UserPropertiesPreferences
  /** Users */
  users: UserConnection
  volunteer?: Maybe<Volunteer>
}

export type QueryAttendeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<AttendeeOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
  state?: InputMaybe<Scalars['String']['input']>
}

export type QueryBoothsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  boothNumber?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  eventPeriod?: InputMaybe<EventPeriodEnum>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<BoothTypeEnum>
}

export type QueryContentStreamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryCsvPreviewDataArgs = {
  csvUploadId: Scalars['ID']['input']
  invitationQuotaId: Scalars['ID']['input']
}

export type QueryDocAcknowledgementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  signable?: InputMaybe<Scalars['ID']['input']>
  tags: Array<Scalars['String']['input']>
}

export type QueryEventArgs = {
  slug: Scalars['String']['input']
}

export type QueryEventByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryEventNotificationsArgs = {
  eventSlug: Scalars['String']['input']
}

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  noParent?: InputMaybe<Scalars['Boolean']['input']>
  parentEventsFirst?: InputMaybe<Scalars['Boolean']['input']>
  period?: InputMaybe<EventPeriodEnum>
  rootEvent?: InputMaybe<Scalars['String']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  startsAtMonth?: InputMaybe<Scalars['Int']['input']>
  startsAtYear?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Array<EventTypeEnum>>
}

export type QueryGeojsonMapsArgs = {
  eventSlug: Scalars['String']['input']
}

export type QueryInterestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryInvitationQuotaArgs = {
  id: Scalars['ID']['input']
}

export type QueryInvitationQuotasArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  partnerCompanyId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryInviteesArgs = {
  activated?: InputMaybe<Scalars['Boolean']['input']>
  invitationQuotaId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  searchQuery?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<InviteeStatusEnum>
}

export type QueryJwtMasterclassArgs = {
  accessToken: Scalars['String']['input']
  slug: Scalars['String']['input']
}

export type QueryLeadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  mainEventId: Scalars['ID']['input']
  partnerCompanyId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryMyOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  type?: InputMaybe<Array<OrderTypeEnum>>
}

export type QueryNewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryNodeArgs = {
  id: Scalars['ID']['input']
}

export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryOrdersArgs = {
  aasmState?: InputMaybe<Array<OrderAasmStateEnum>>
  after?: InputMaybe<Scalars['String']['input']>
  badgeIds?: InputMaybe<Array<Scalars['String']['input']>>
  before?: InputMaybe<Scalars['String']['input']>
  canceledAtMonth?: InputMaybe<Scalars['Int']['input']>
  canceledAtYear?: InputMaybe<Scalars['Int']['input']>
  createdAtMonth?: InputMaybe<Scalars['Int']['input']>
  createdAtYear?: InputMaybe<Scalars['Int']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  eventStartsAtMonth?: InputMaybe<Scalars['Int']['input']>
  eventStartsAtYear?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  paidAtMonth?: InputMaybe<Scalars['Int']['input']>
  paidAtYear?: InputMaybe<Scalars['Int']['input']>
  paymentMethod?: InputMaybe<Array<PaymentMethodEnum>>
  payoneIds?: InputMaybe<Array<Scalars['String']['input']>>
  type?: InputMaybe<Array<OrderTypeEnum>>
}

export type QueryPageVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  categorySlugs?: InputMaybe<Array<Scalars['String']['input']>>
  createdDaysAgo?: InputMaybe<Scalars['Int']['input']>
  excludeCompanyNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  excludeCompanyWebsites?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  first?: InputMaybe<Scalars['Int']['input']>
  from?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  identifier?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  productSlugs?: InputMaybe<Array<Scalars['String']['input']>>
  project?: InputMaybe<Scalars['String']['input']>
  recurring?: InputMaybe<Scalars['Boolean']['input']>
  subjectId?: InputMaybe<Array<Scalars['String']['input']>>
  subjectType?: InputMaybe<Scalars['String']['input']>
  to?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type QueryPartnerCompaniesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  interests?: InputMaybe<Array<Scalars['String']['input']>>
  last?: InputMaybe<Scalars['Int']['input']>
  onlyFamilyMembers?: InputMaybe<Scalars['Boolean']['input']>
  stageId?: InputMaybe<Scalars['String']['input']>
}

export type QueryProspectListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  partnerCompanyId: Scalars['ID']['input']
  period?: InputMaybe<EventPeriodEnum>
}

export type QueryProspectsArgs = {
  available?: InputMaybe<Scalars['Boolean']['input']>
  companyDepartments?: InputMaybe<Array<Scalars['ID']['input']>>
  companyFields?: InputMaybe<Array<Scalars['ID']['input']>>
  companyIndustries?: InputMaybe<Array<Scalars['ID']['input']>>
  companyJobLevels?: InputMaybe<Array<Scalars['ID']['input']>>
  companyName?: InputMaybe<Scalars['String']['input']>
  companySizes?: InputMaybe<Array<Scalars['ID']['input']>>
  page?: InputMaybe<Scalars['Int']['input']>
  perPage?: InputMaybe<Scalars['Int']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  visitedEvents?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QuerySearchArgs = {
  term: Scalars['String']['input']
}

export type QuerySpeakersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  forCurrentPartnerCompany?: InputMaybe<Scalars['Boolean']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  searchTerm?: InputMaybe<Scalars['String']['input']>
}

export type QueryStageArgs = {
  slug: Scalars['String']['input']
}

export type QueryTicketArgs = {
  identifier: Scalars['String']['input']
}

export type QueryTicketsArgs = {
  aasmState?: InputMaybe<Array<TicketAasmStateEnum>>
  activated?: InputMaybe<Scalars['Boolean']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  eventId?: InputMaybe<Scalars['ID']['input']>
  eventSlug?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  invitationQuotaId?: InputMaybe<Scalars['ID']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  orderBy?: InputMaybe<TicketOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
  search?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<Array<TicketSourceEnum>>
  swapped?: InputMaybe<Scalars['Boolean']['input']>
  updatedAt?: InputMaybe<Scalars['ISO8601DateTime']['input']>
}

export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  globalId?: InputMaybe<Scalars['ID']['input']>
  id?: InputMaybe<Scalars['String']['input']>
}

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated input type of ResendConfirmationInstructions */
export type ResendConfirmationInstructionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of ResendConfirmationInstructions. */
export type ResendConfirmationInstructionsPayload = {
  __typename?: 'ResendConfirmationInstructionsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  me: User
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of ResendTicketMail */
export type ResendTicketMailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of ResendTicketMail. */
export type ResendTicketMailPayload = {
  __typename?: 'ResendTicketMailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export enum ResourceTypeEnum {
  CodeQuota = 'CodeQuota',
  CrewQuota = 'CrewQuota',
  Event = 'Event',
  ExhibitorQuota = 'ExhibitorQuota',
  GuestQuota = 'GuestQuota',
  PartnerCompany = 'PartnerCompany',
}

/** Autogenerated input type of RetractVolunteer */
export type RetractVolunteerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

export type ReviewsAsset = {
  __typename?: 'ReviewsAsset'
  contentType: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Role = {
  __typename?: 'Role'
  id: Scalars['ID']['output']
  name: RoleNameEnum
  resourceId?: Maybe<Scalars['String']['output']>
  resourceType?: Maybe<ResourceTypeEnum>
}

export enum RoleNameEnum {
  AccessesAdmin = 'accesses_admin',
  /** @deprecated deprecated */
  Admin = 'admin',
  AdminActsAsTaggableOnTags = 'admin__acts_as_taggable_on_tags',
  AdminAdminUserAccesses = 'admin__admin_user_accesses',
  AdminAdminUserGroups = 'admin__admin_user_groups',
  AdminAllocations = 'admin__allocations',
  AdminBadgeOrders = 'admin__badge_orders',
  AdminBadgeTransactionItems = 'admin__badge_transaction_items',
  AdminBadgeTransactions = 'admin__badge_transactions',
  AdminBadges = 'admin__badges',
  AdminBooths = 'admin__booths',
  AdminComments = 'admin__comments',
  AdminCompanies = 'admin__companies',
  AdminCompanyFields = 'admin__company_fields',
  AdminCompanyIndustries = 'admin__company_industries',
  AdminContacts = 'admin__contacts',
  AdminContentStreams = 'admin__content_streams',
  AdminDashboard = 'admin__dashboard',
  AdminDeals = 'admin__deals',
  AdminDigitalMasterclasses = 'admin__digital_masterclasses',
  AdminDocAcknowledgements = 'admin__doc_acknowledgements',
  AdminDocuments = 'admin__documents',
  AdminDueDates = 'admin__due_dates',
  AdminEventApplications = 'admin__event_applications',
  AdminEventNotifications = 'admin__event_notifications',
  AdminEventPartnerCompanies = 'admin__event_partner_companies',
  AdminEventSpeakers = 'admin__event_speakers',
  AdminEvents = 'admin__events',
  AdminFieldCategories = 'admin__field_categories',
  AdminFields = 'admin__fields',
  AdminInterests = 'admin__interests',
  AdminInvitationQuotas = 'admin__invitation_quotas',
  AdminInvitees = 'admin__invitees',
  AdminJobOrders = 'admin__job_orders',
  AdminLeads = 'admin__leads',
  AdminLocations = 'admin__locations',
  AdminMasterclasses = 'admin__masterclasses',
  AdminNews = 'admin__news',
  AdminOutgoingWebhooks = 'admin__outgoing_webhooks',
  AdminPartnerCompanies = 'admin__partner_companies',
  AdminPartnerPages = 'admin__partner_pages',
  AdminPassbookConfigs = 'admin__passbook_configs',
  AdminPaymentInfos = 'admin__payment_infos',
  AdminPipedriveConfigs = 'admin__pipedrive_configs',
  AdminProductCategories = 'admin__product_categories',
  AdminProducts = 'admin__products',
  AdminPromoCodeConfigs = 'admin__promo_code_configs',
  AdminPromoCodes = 'admin__promo_codes',
  AdminProspectItems = 'admin__prospect_items',
  AdminProspectLists = 'admin__prospect_lists',
  AdminRedirects = 'admin__redirects',
  AdminReportOrders = 'admin__report_orders',
  AdminSearchTracks = 'admin__search_tracks',
  AdminSpeakers = 'admin__speakers',
  AdminStages = 'admin__stages',
  AdminTicketCategories = 'admin__ticket_categories',
  AdminTicketOrders = 'admin__ticket_orders',
  AdminTickets = 'admin__tickets',
  AdminUsers = 'admin__users',
  AdminUsersRoles = 'admin__users_roles',
  AfterPaymentAdmin = 'after_payment_admin',
  AttendeeAllocationAdmin = 'attendee_allocation_admin',
  AttendeeAllocationSuperAdmin = 'attendee_allocation_super_admin',
  /** @deprecated deprecated */
  BoothBuilder = 'booth_builder',
  DeleteAdmin = 'delete_admin',
  EditTicketFieldsAdmin = 'edit_ticket_fields_admin',
  /** @deprecated deprecated */
  Employee = 'employee',
  GeojsonAdmin = 'geojson_admin',
  InternalApiUser = 'internal_api_user',
  InvalidateQuotaAdmin = 'invalidate_quota_admin',
  /** @deprecated deprecated */
  LeadGen = 'lead_gen',
  /** @deprecated deprecated */
  LeadScanner = 'lead_scanner',
  ManageBooths = 'manage__booths',
  ManageCompanyProfile = 'manage__company_profile',
  ManageDashboard = 'manage__dashboard',
  ManageEvents = 'manage__events',
  ManageEventsInvitees = 'manage__events_invitees',
  ManageLeadGeneration = 'manage__lead_generation',
  ManageLeadScanner = 'manage__lead_scanner',
  ManageMyLeads = 'manage__my_leads',
  ManageReviewsManagement = 'manage__reviews_management',
  ManageSpeakers = 'manage__speakers',
  ManageTickets = 'manage__tickets',
  ManageUserManagement = 'manage__user_management',
  MergeAdmin = 'merge_admin',
  MobileTester = 'mobile_tester',
  /** @deprecated deprecated */
  ProjectManager = 'project_manager',
  QuotaUser = 'quota_user',
  SlugsAdmin = 'slugs_admin',
  SyncTicketFieldsAdmin = 'sync_ticket_fields_admin',
  /** @deprecated deprecated */
  TicketAdmin = 'ticket_admin',
  UnlockTicketAdmin = 'unlock_ticket_admin',
}

export enum SalutationTypeEnum {
  Female = 'female',
  Male = 'male',
  Other = 'other',
}

export type Salutations = {
  __typename?: 'Salutations'
  enum: SalutationTypeEnum
  name: Scalars['String']['output']
}

export type SearchResult = {
  __typename?: 'SearchResult'
  agencies: AgencyMatchPaginatedCollection
  dailyArticles: ArticleMatchPaginatedCollection
  educationReports: EducationReportMatchPaginatedCollection
  educationSeminars: EducationSeminarMatchPaginatedCollection
  jobs: JobMatchPaginatedCollection
  partnerCompanies: PartnerCompanyMatchPaginatedCollection
  podcasts: PodcastMatchPaginatedCollection
  products: ProductMatchPaginatedCollection
  speakers: SpeakerMatchPaginatedCollection
}

export type SearchResultDailyArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultEducationReportsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultEducationSeminarsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultJobsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultPartnerCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultPodcastsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultSpeakersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type SearchResultHighlight = {
  __typename?: 'SearchResultHighlight'
  companyName?: Maybe<Scalars['String']['output']>
  companyPosition?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of SendIndividualInvitations */
export type SendIndividualInvitationsInput = {
  cc?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  emails: Array<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
  messageBody: Scalars['String']['input']
  messageSubject: Scalars['String']['input']
  replyTo: Scalars['String']['input']
}

/** Autogenerated return type of SendIndividualInvitations. */
export type SendIndividualInvitationsPayload = {
  __typename?: 'SendIndividualInvitationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendInvitations */
export type SendInvitationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of SendInvitations. */
export type SendInvitationsPayload = {
  __typename?: 'SendInvitationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendProspectListInvitations */
export type SendProspectListInvitationsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of SendProspectListInvitations. */
export type SendProspectListInvitationsPayload = {
  __typename?: 'SendProspectListInvitationsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendTestEmail */
export type SendTestEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of SendTestEmail. */
export type SendTestEmailPayload = {
  __typename?: 'SendTestEmailPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SendTestInvitation */
export type SendTestInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of SendTestInvitation. */
export type SendTestInvitationPayload = {
  __typename?: 'SendTestInvitationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of SetPaymentInfo */
export type SetPaymentInfoInput = {
  attributes: TicketPaymentInfoAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of SetPaymentInfo. */
export type SetPaymentInfoPayload = {
  __typename?: 'SetPaymentInfoPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

export type Shift = {
  __typename?: 'Shift'
  briefing?: Maybe<Scalars['String']['output']>
  duration?: Maybe<Scalars['String']['output']>
  endsAt?: Maybe<Scalars['String']['output']>
  hourlyWage?: Maybe<Scalars['String']['output']>
  info?: Maybe<Scalars['String']['output']>
  meetingPointName?: Maybe<Scalars['String']['output']>
  meetingPointUrl?: Maybe<Scalars['String']['output']>
  meetingbox?: Maybe<Scalars['String']['output']>
  pause?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  safetyBriefing?: Maybe<Array<AirtableAttachment>>
  shift?: Maybe<Scalars['String']['output']>
  startsAt?: Maybe<Scalars['String']['output']>
  team?: Maybe<Scalars['String']['output']>
  /** @deprecated Use teamlead_names instead */
  teamlead?: Maybe<Scalars['String']['output']>
  teamleadNames?: Maybe<Scalars['String']['output']>
  type?: Maybe<Scalars['String']['output']>
  workspace?: Maybe<Scalars['String']['output']>
}

export enum SourceEnum {
  FreeOnlineSeminars = 'free_online_seminars',
  Instagram = 'instagram',
  LinkedIn = 'linkedIn',
  MentionInAPodcast = 'mention_in_a_podcast',
  OmrDeepDive = 'omr_deep_dive',
  OmrFestival = 'omr_festival',
  Other = 'other',
  PersonalRecommendation = 'personal_recommendation',
  Press = 'press',
  SearchEngine = 'search_engine',
}

export type Speaker = Node & {
  __typename?: 'Speaker'
  createdAt: Scalars['ISO8601DateTime']['output']
  description?: Maybe<Scalars['String']['output']>
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  editorialFlag?: Maybe<Scalars['Boolean']['output']>
  email?: Maybe<Scalars['String']['output']>
  eventSpeakers?: Maybe<Array<EventSpeaker>>
  events?: Maybe<EventConnectionWithTotalCount>
  facebookUrl?: Maybe<Scalars['String']['output']>
  firstname?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  instagramUrl?: Maybe<Scalars['String']['output']>
  interests?: Maybe<Array<Interest>>
  lastname?: Maybe<Scalars['String']['output']>
  linkedinUrl?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  omrSpeakerProfileUrl?: Maybe<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
  partnerCompanyLogoUrl?: Maybe<Scalars['String']['output']>
  partnerCompanyName?: Maybe<Scalars['String']['output']>
  photoUrl?: Maybe<Scalars['String']['output']>
  /** @deprecated position is deprecated, use title instead */
  position?: Maybe<Scalars['String']['output']>
  seoDescription?: Maybe<Scalars['String']['output']>
  seoTitle?: Maybe<Scalars['String']['output']>
  slug?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  twitterUrl?: Maybe<Scalars['String']['output']>
  websiteUrl?: Maybe<Scalars['String']['output']>
  youtubeUrl?: Maybe<Scalars['String']['output']>
}

export type SpeakerEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** Attributes for creating or updating an speaker */
export type SpeakerAttributes = {
  /** german description */
  description?: InputMaybe<Scalars['String']['input']>
  /** german description */
  descriptionDe?: InputMaybe<Scalars['String']['input']>
  /** englisch description */
  descriptionEn?: InputMaybe<Scalars['String']['input']>
  /** email */
  email?: InputMaybe<Scalars['String']['input']>
  /** facebook url */
  facebookUrl?: InputMaybe<Scalars['String']['input']>
  /** first name */
  firstname?: InputMaybe<Scalars['String']['input']>
  /** insagram url */
  instagramUrl?: InputMaybe<Scalars['String']['input']>
  /** Interests */
  interests?: InputMaybe<Array<Scalars['ID']['input']>>
  /** lastname */
  lastname?: InputMaybe<Scalars['String']['input']>
  /** linkedin url */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>
  /** name */
  name?: InputMaybe<Scalars['String']['input']>
  /** partner company name */
  partnerCompanyName?: InputMaybe<Scalars['String']['input']>
  photo?: InputMaybe<Scalars['Upload']['input']>
  /** seo description */
  seoDescription?: InputMaybe<Scalars['String']['input']>
  /** seo title */
  seoTitle?: InputMaybe<Scalars['String']['input']>
  /** title */
  title?: InputMaybe<Scalars['String']['input']>
  /** twitter url */
  twitterUrl?: InputMaybe<Scalars['String']['input']>
  /** website url */
  websiteUrl?: InputMaybe<Scalars['String']['input']>
  /** youtube url */
  youtubeUrl?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Speaker. */
export type SpeakerConnection = {
  __typename?: 'SpeakerConnection'
  /** A list of edges. */
  edges: Array<SpeakerEdge>
  /** A list of nodes. */
  nodes: Array<Speaker>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type SpeakerEdge = {
  __typename?: 'SpeakerEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Speaker>
}

export type SpeakerMatch = {
  __typename?: 'SpeakerMatch'
  highlight?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  photoUrl: Scalars['String']['output']
  slug: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type SpeakerMatchPaginatedCollection = {
  __typename?: 'SpeakerMatchPaginatedCollection'
  nodes: Array<SpeakerMatch>
  pageInfo: OffsetPaginationInfo
}

export enum SpeakerRoleEnum {
  Artist = 'Artist',
  Speaker = 'Speaker',
}

export type Stage = Node & {
  __typename?: 'Stage'
  events: Array<Event>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  /** @deprecated deprecated, use name instead */
  nameDe?: Maybe<Scalars['String']['output']>
  /** @deprecated deprecated, use name instead */
  nameEn?: Maybe<Scalars['String']['output']>
  partnerCompanies?: Maybe<Array<PartnerCompany>>
  slug: Scalars['String']['output']
  speakers: Array<Speaker>
  subtitleDe?: Maybe<Scalars['String']['output']>
  subtitleEn?: Maybe<Scalars['String']['output']>
  timetablePosition?: Maybe<Scalars['Int']['output']>
}

/** Autogenerated input type of SubmitProspectList */
export type SubmitProspectListInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of SubmitProspectList. */
export type SubmitProspectListPayload = {
  __typename?: 'SubmitProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  prospectList?: Maybe<ProspectList>
}

/** Autogenerated input type of SubmitVolunteer */
export type SubmitVolunteerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

export type Summary = {
  __typename?: 'Summary'
  bulkDiscounts: Array<BulkDiscount>
  errors?: Maybe<Array<Error>>
  lineItems: Array<LineItem>
  promoCodeDiscount?: Maybe<PromoCodeDiscount>
  totals: SummaryTotals
}

export type SummaryTotals = {
  __typename?: 'SummaryTotals'
  gross: Scalars['Int']['output']
  net: Scalars['Int']['output']
  taxes: Scalars['Int']['output']
}

export enum TagListTypeEnum {
  BrandedRoomBooth = 'BrandedRoomBooth',
  BreakEvent = 'BreakEvent',
  CareerMeetup = 'CareerMeetup',
  CoExhibitorBooth = 'CoExhibitorBooth',
  DeepDive = 'DeepDive',
  DigitalMasterclass = 'DigitalMasterclass',
  Event = 'Event',
  GastroBooth = 'GastroBooth',
  GuidedTour = 'GuidedTour',
  Masterclass = 'Masterclass',
  PremiumBooth = 'PremiumBooth',
  PremiumLoungeBooth = 'PremiumLoungeBooth',
  PremiumOutdoorBooth = 'PremiumOutdoorBooth',
  SamplingBooth = 'SamplingBooth',
  SeacontainerBooth = 'SeacontainerBooth',
  SideEvent = 'SideEvent',
  StageSlot = 'StageSlot',
  StartupBooth = 'StartupBooth',
  SystemBooth = 'SystemBooth',
}

export type Ticket = {
  __typename?: 'Ticket'
  aasmState: TicketAasmStateEnum
  ableToCheckIn: Scalars['Boolean']['output']
  accreditationMessage?: Maybe<Scalars['String']['output']>
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>
  companyDepartment?: Maybe<CompanyDepartmentEnum>
  companyFieldId?: Maybe<Scalars['Int']['output']>
  companyIndustryId?: Maybe<Scalars['Int']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companySize?: Maybe<CompanySizeEnum>
  companyType?: Maybe<CompanyTypeEnum>
  country?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['String']['output']
  deactivatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  email?: Maybe<Scalars['String']['output']>
  ffwdConsent?: Maybe<Scalars['Boolean']['output']>
  fields?: Maybe<Array<Field>>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  identifier: Scalars['String']['output']
  isDataComplete: Scalars['Boolean']['output']
  isEditable: Scalars['Boolean']['output']
  isPaymentInfoEnabled: Scalars['Boolean']['output']
  isTicketCategoryEnded: Scalars['Boolean']['output']
  jobLevel?: Maybe<JobLevelEnum>
  lastName?: Maybe<Scalars['String']['output']>
  merchSize?: Maybe<MerchSizeEnum>
  omrMatchConsent?: Maybe<Scalars['Boolean']['output']>
  onsite: Onsite
  ownership?: Maybe<TicketOwnershipTypeEnum>
  partnerName?: Maybe<Scalars['String']['output']>
  partnerRole?: Maybe<Scalars['String']['output']>
  passbookDownloadUrl?: Maybe<Scalars['String']['output']>
  paymentInfo?: Maybe<PaymentInfo>
  pdfDownloadUrl?: Maybe<Scalars['String']['output']>
  phone?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  primaryFieldCategory?: Maybe<FieldCategory>
  qrCodePayload: Scalars['String']['output']
  qrCodeUrl?: Maybe<Scalars['String']['output']>
  salutation?: Maybe<SalutationTypeEnum>
  source: TicketSourceEnum
  /** Is true if user has swapped the ticket for a badge */
  swapped: Scalars['Boolean']['output']
  ticketCategory: TicketCategory
  title?: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['String']['output']
  user?: Maybe<User>
}

export enum TicketAasmStateEnum {
  Canceled = 'canceled',
  Completed = 'completed',
  Locked = 'locked',
  Unlocked = 'unlocked',
}

export type TicketCategory = {
  __typename?: 'TicketCategory'
  descriptionDe?: Maybe<Scalars['String']['output']>
  descriptionEn?: Maybe<Scalars['String']['output']>
  endTime?: Maybe<Scalars['String']['output']>
  event: Event
  getBackgroundColor?: Maybe<Scalars['String']['output']>
  getTextColor?: Maybe<Scalars['String']['output']>
  hashId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  location?: Maybe<Scalars['String']['output']>
  offerEndTime?: Maybe<Scalars['String']['output']>
  price?: Maybe<Scalars['Float']['output']>
  public: Scalars['Boolean']['output']
  requiresFfwdConsent: Scalars['Boolean']['output']
  startTime?: Maybe<Scalars['String']['output']>
  title: Scalars['String']['output']
  titleDe?: Maybe<Scalars['String']['output']>
  titleEn?: Maybe<Scalars['String']['output']>
}

/** Input type for a single ticket category */
export type TicketCategoryInput = {
  count: Scalars['Int']['input']
  ticketCategoryId: Scalars['String']['input']
}

/** The connection type for Ticket. */
export type TicketConnection = {
  __typename?: 'TicketConnection'
  canceledCount: Scalars['Int']['output']
  completedCount: Scalars['Int']['output']
  /** A list of edges. */
  edges: Array<TicketEdge>
  lockedCount: Scalars['Int']['output']
  /** A list of nodes. */
  nodes: Array<Ticket>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
  unlockedCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type TicketEdge = {
  __typename?: 'TicketEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Ticket>
}

export enum TicketEvent {
  Cancel = 'cancel',
  Complete = 'complete',
  Unlock = 'unlock',
}

export enum TicketOrderAttributesEnum {
  AasmState = 'aasm_state',
  CreatedAt = 'created_at',
  Id = 'id',
  UpdatedAt = 'updated_at',
}

export enum TicketOwnershipTypeEnum {
  Me = 'me',
  Other = 'other',
}

export type TicketPaymentInfoAttributes = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>
  address: Scalars['String']['input']
  business: Scalars['Boolean']['input']
  city: Scalars['String']['input']
  company?: InputMaybe<Scalars['String']['input']>
  country: Scalars['String']['input']
  customerReference?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  salutation: Scalars['String']['input']
  vatNumber?: InputMaybe<Scalars['String']['input']>
  zip: Scalars['String']['input']
}

export type TicketShopQuery = {
  __typename?: 'TicketShopQuery'
  /** Ticket shop compute */
  compute: Summary
  /** Ticket Categories for the ticket shop */
  ticketCategories: Array<TicketShopTicketCategory>
  /** tickets */
  tickets: Array<Ticket>
}

export type TicketShopQueryComputeArgs = {
  inviteeToken?: InputMaybe<Scalars['String']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  ticketCategories: Array<TicketCategoryInput>
}

export type TicketShopQueryTicketCategoriesArgs = {
  eventSlug: Scalars['String']['input']
  inviteeToken?: InputMaybe<Scalars['String']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
}

export type TicketShopQueryTicketsArgs = {
  afterPaymentToken: Scalars['String']['input']
  orderBy?: InputMaybe<TicketOrderAttributesEnum>
  orderDirection?: InputMaybe<OrderDirectionEnum>
}

export type TicketShopTicketCategory = {
  __typename?: 'TicketShopTicketCategory'
  bulkDiscount?: Maybe<Scalars['CentsAmount']['output']>
  bulkDiscountActivationLimit?: Maybe<Scalars['Int']['output']>
  currentPromoCode?: Maybe<Scalars['String']['output']>
  currentPromoCodeTimesUsable?: Maybe<Scalars['Int']['output']>
  description: Scalars['String']['output']
  descriptionDe: Scalars['String']['output']
  descriptionEn: Scalars['String']['output']
  discountAmount?: Maybe<Scalars['CentsAmount']['output']>
  discountPercentage?: Maybe<Scalars['Int']['output']>
  discountedPriceFromGross: Scalars['CentsAmount']['output']
  endTime: Scalars['ISO8601DateTime']['output']
  event: PublishedEvent
  expired: Scalars['Boolean']['output']
  fields: Array<Field>
  grossDiscount: Scalars['CentsAmount']['output']
  grossPrice: Scalars['CentsAmount']['output']
  id: Scalars['ID']['output']
  inMultiplesOf: Scalars['Int']['output']
  location: Scalars['String']['output']
  maxPurchaseable: Scalars['Int']['output']
  offerEndTime?: Maybe<Scalars['ISO8601DateTime']['output']>
  period: Scalars['String']['output']
  price: Scalars['CentsAmount']['output']
  public: Scalars['Boolean']['output']
  startTime: Scalars['ISO8601DateTime']['output']
  /** The regular price of the ticket outside any promotion period. */
  strikeThroughPrice?: Maybe<Scalars['CentsAmount']['output']>
  title: Scalars['String']['output']
  titleDe: Scalars['String']['output']
  titleEn: Scalars['String']['output']
}

export enum TicketSourceEnum {
  Airtable = 'airtable',
  Default = 'default',
  DmcSpeakerTicket = 'dmc_speaker_ticket',
  OnDemand = 'on_demand',
  RequestForm = 'request_form',
}

/** Attributes for updating a ticket */
export type TicketUpdateAttributes = {
  companyDepartment?: InputMaybe<CompanyDepartmentEnum>
  companyFieldId?: InputMaybe<Scalars['Int']['input']>
  companyIndustryId?: InputMaybe<Scalars['Int']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companySize?: InputMaybe<CompanySizeEnum>
  companyType?: InputMaybe<CompanyTypeEnum>
  country?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  ffwdConsent?: InputMaybe<Scalars['Boolean']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  jobLevel?: InputMaybe<JobLevelEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  omrMatchConsent?: InputMaybe<Scalars['Boolean']['input']>
  ownership?: InputMaybe<TicketOwnershipTypeEnum>
  partnerName?: InputMaybe<Scalars['String']['input']>
  partnerRole?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  salutation?: InputMaybe<SalutationTypeEnum>
  title?: InputMaybe<TitleTypeEnum>
}

export type Timetable = {
  __typename?: 'Timetable'
  events: Array<PublishedEvent>
}

export enum TitleTypeEnum {
  DiplIng = 'dipl_ing',
  Dr = 'dr',
  Ing = 'ing',
  Mag = 'mag',
  Prof = 'prof',
  ProfDr = 'prof_dr',
}

export enum TransactionTypeEnum {
  Credit = 'credit',
  Debit = 'debit',
}

/** Autogenerated input type of UpdateAcknowledgement */
export type UpdateAcknowledgementInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  docAcknowledgementId: Scalars['ID']['input']
  document?: InputMaybe<Scalars['Upload']['input']>
}

/** Autogenerated return type of UpdateAcknowledgement. */
export type UpdateAcknowledgementPayload = {
  __typename?: 'UpdateAcknowledgementPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  docAcknowledgement?: Maybe<DocAcknowledgement>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateAttendeesStateFromCsv */
export type UpdateAttendeesStateFromCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  csvFile: Scalars['Upload']['input']
  eventId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateAttendeesStateFromCsv. */
export type UpdateAttendeesStateFromCsvPayload = {
  __typename?: 'UpdateAttendeesStateFromCsvPayload'
  accepted?: Maybe<Scalars['Int']['output']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  denied?: Maybe<Scalars['Int']['output']>
  errors: Array<Scalars['String']['output']>
  failed?: Maybe<Scalars['Int']['output']>
  notChanged?: Maybe<Scalars['Int']['output']>
}

/** Autogenerated input type of UpdateBillingAddress */
export type UpdateBillingAddressInput = {
  attributes: BillingAddressAttributes
  billingAddressId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateBillingAddress. */
export type UpdateBillingAddressPayload = {
  __typename?: 'UpdateBillingAddressPayload'
  billingAddress?: Maybe<BillingAddress>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateBooth */
export type UpdateBoothInput = {
  attributes: BoothAttributes
  boothId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateBooth. */
export type UpdateBoothPayload = {
  __typename?: 'UpdateBoothPayload'
  booth?: Maybe<Booth>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateContact */
export type UpdateContactInput = {
  attributes: ContactAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contactId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateContact. */
export type UpdateContactPayload = {
  __typename?: 'UpdateContactPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  contact?: Maybe<Contact>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateDirectTicket */
export type UpdateDirectTicketInput = {
  attributes: DirectTicketAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Events to be triggered on ticket after update */
  events?: InputMaybe<Array<TicketEvent>>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateDirectTicket. */
export type UpdateDirectTicketPayload = {
  __typename?: 'UpdateDirectTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of UpdateEvent */
export type UpdateEventInput = {
  attributes: EventAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  eventId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateEvent. */
export type UpdateEventPayload = {
  __typename?: 'UpdateEventPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  event?: Maybe<Event>
}

/** Autogenerated input type of UpdateEventStarrings */
export type UpdateEventStarringsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  updates: Array<EventStarringsUpdate>
}

/** Autogenerated return type of UpdateEventStarrings. */
export type UpdateEventStarringsPayload = {
  __typename?: 'UpdateEventStarringsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of UpdateExtras */
export type UpdateExtrasInput = {
  boothId: Scalars['ID']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  extras: Array<ExtraAttributes>
}

/** Autogenerated return type of UpdateExtras. */
export type UpdateExtrasPayload = {
  __typename?: 'UpdateExtrasPayload'
  booth?: Maybe<Booth>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpdateInterests */
export type UpdateInterestsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  updates: Array<InterestsUpdate>
}

/** Autogenerated return type of UpdateInterests. */
export type UpdateInterestsPayload = {
  __typename?: 'UpdateInterestsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors?: Maybe<Array<Scalars['String']['output']>>
  me?: Maybe<User>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of UpdateLead */
export type UpdateLeadInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  scannedTicket: Scalars['String']['input']
  tags?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateLead. */
export type UpdateLeadPayload = {
  __typename?: 'UpdateLeadPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  lead?: Maybe<Lead>
}

/** Autogenerated input type of UpdateMe */
export type UpdateMeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  locale?: InputMaybe<LocaleEnum>
  reviewsGuidelinesAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UpdateMe. */
export type UpdateMePayload = {
  __typename?: 'UpdateMePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Attributes for updating orders */
export type UpdateOrderAttributes = {
  additionalAddress?: InputMaybe<Scalars['String']['input']>
  business?: InputMaybe<Scalars['Boolean']['input']>
  city?: InputMaybe<Scalars['String']['input']>
  company?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  customerReference?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  number?: InputMaybe<Scalars['String']['input']>
  salutation?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  vatNumber?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  afterPaymentToken: Scalars['String']['input']
  attributes: UpdateOrderAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateOrder. */
export type UpdateOrderPayload = {
  __typename?: 'UpdateOrderPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  order?: Maybe<CheckoutOrder>
}

/** Autogenerated input type of UpdatePartnerCompany */
export type UpdatePartnerCompanyInput = {
  attributes: PartnerCompanyAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  partnerCompanyId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdatePartnerCompany. */
export type UpdatePartnerCompanyPayload = {
  __typename?: 'UpdatePartnerCompanyPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  partnerCompany?: Maybe<PartnerCompany>
}

/** Autogenerated input type of UpdateProspectList */
export type UpdateProspectListInput = {
  attributes: ProspectListAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  prospectListId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateProspectList. */
export type UpdateProspectListPayload = {
  __typename?: 'UpdateProspectListPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  prospectList?: Maybe<ProspectList>
}

/** Autogenerated input type of UpdateSpeaker */
export type UpdateSpeakerInput = {
  attributes: SpeakerAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  speakerId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateSpeaker. */
export type UpdateSpeakerPayload = {
  __typename?: 'UpdateSpeakerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  speaker?: Maybe<Speaker>
}

/** Autogenerated input type of UpdateTicket */
export type UpdateTicketInput = {
  attributes: TicketUpdateAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ticketId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateTicket. */
export type UpdateTicketPayload = {
  __typename?: 'UpdateTicketPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  ticket?: Maybe<Ticket>
}

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  attributes: UserAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateUserRoles */
export type UpdateUserRolesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Role names */
  roleNames?: InputMaybe<Array<RoleNameEnum>>
  userId: Scalars['ID']['input']
}

/** Autogenerated return type of UpdateUserRoles. */
export type UpdateUserRolesPayload = {
  __typename?: 'UpdateUserRolesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  user?: Maybe<User>
}

/** Autogenerated input type of UploadInvitees */
export type UploadInviteesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  csvFile: Scalars['Upload']['input']
  invitationQuotaId: Scalars['ID']['input']
}

/** Autogenerated return type of UploadInvitees. */
export type UploadInviteesPayload = {
  __typename?: 'UploadInviteesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  csvPreviewData?: Maybe<Array<CsvPreviewData>>
  csvUploadId?: Maybe<Scalars['ID']['output']>
  errors: Array<Scalars['String']['output']>
}

/** Autogenerated input type of UpsertLeads */
export type UpsertLeadsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  leadAttributes: Array<LeadAttributes>
}

/** Autogenerated return type of UpsertLeads. */
export type UpsertLeadsPayload = {
  __typename?: 'UpsertLeadsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errors: Array<Scalars['String']['output']>
  leads?: Maybe<Array<Lead>>
}

/** Autogenerated input type of UpsertVolunteer */
export type UpsertVolunteerInput = {
  attributes: VolunteerAttributes
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

export type User = Node & {
  __typename?: 'User'
  attendingEvents: Array<PublishedEvent>
  birthdate?: Maybe<Scalars['String']['output']>
  companyAdditionalAddress?: Maybe<Scalars['String']['output']>
  companyCity?: Maybe<Scalars['String']['output']>
  companyCountry?: Maybe<Scalars['String']['output']>
  companyDepartment?: Maybe<CompanyDepartmentEnum>
  companyEmail?: Maybe<Scalars['String']['output']>
  companyFieldId?: Maybe<Scalars['ID']['output']>
  companyFieldName?: Maybe<Scalars['String']['output']>
  companyHomepage?: Maybe<Scalars['String']['output']>
  companyIndustryId?: Maybe<Scalars['ID']['output']>
  companyIndustryName?: Maybe<Scalars['String']['output']>
  companyName?: Maybe<Scalars['String']['output']>
  companyNumber?: Maybe<Scalars['String']['output']>
  companyPhone?: Maybe<Scalars['String']['output']>
  companySize?: Maybe<CompanySizeEnum>
  companyStreet?: Maybe<Scalars['String']['output']>
  companyZip?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['ISO8601DateTime']['output']
  currentTransactions?: Maybe<Array<BadgeTransaction>>
  digitalMasterclasses: Array<DigitalMasterclass>
  email: Scalars['String']['output']
  eventApplications: Array<EventApplication>
  eventStarrings: Array<Scalars['ID']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  interests: Array<Interest>
  jobLevel?: Maybe<JobLevelEnum>
  lastName?: Maybe<Scalars['String']['output']>
  locale: Scalars['String']['output']
  /** @deprecated masterclasses is deprecated, use attending_events */
  masterclasses: Array<PublishedEvent>
  name?: Maybe<Scalars['String']['output']>
  networkingAccepted: Scalars['Boolean']['output']
  newsTags?: Maybe<Array<Scalars['String']['output']>>
  oauthProviders: Array<OauthProviderEnum>
  omrMatchConsent: Scalars['Boolean']['output']
  omrMatchConsentDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  partnerCompanies?: Maybe<Array<PartnerCompany>>
  partnerCompany?: Maybe<PartnerCompany>
  phone?: Maybe<Scalars['String']['output']>
  position?: Maybe<Scalars['String']['output']>
  profileImage: Scalars['String']['output']
  profileScopes: Array<ProfileScopeEnum>
  reviewsGuidelinesAccepted?: Maybe<Scalars['Boolean']['output']>
  roles: Array<Role>
  salutation?: Maybe<SalutationTypeEnum>
  spentAmount?: Maybe<Scalars['Float']['output']>
  tickets: Array<Ticket>
  unconfirmedEmail?: Maybe<Scalars['String']['output']>
}

export type UserAttendingEventsArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserCurrentTransactionsArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserDigitalMasterclassesArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserEventApplicationsArgs = {
  ticketId: Scalars['ID']['input']
}

export type UserEventStarringsArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserMasterclassesArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserSpentAmountArgs = {
  eventSlug: Scalars['String']['input']
}

export type UserTicketsArgs = {
  aasmState?: InputMaybe<Array<TicketAasmStateEnum>>
  eventSlug: Scalars['String']['input']
}

/** Attributes for updating a user */
export type UserAttributes = {
  birthdate?: InputMaybe<Scalars['String']['input']>
  companyAdditionalAddress?: InputMaybe<Scalars['String']['input']>
  companyCity?: InputMaybe<Scalars['String']['input']>
  companyCountry?: InputMaybe<Scalars['String']['input']>
  companyDepartment?: InputMaybe<CompanyDepartmentEnum>
  companyEmail?: InputMaybe<Scalars['String']['input']>
  companyFieldId?: InputMaybe<Scalars['ID']['input']>
  companyHomepage?: InputMaybe<Scalars['String']['input']>
  companyIndustryId?: InputMaybe<Scalars['ID']['input']>
  companyName?: InputMaybe<Scalars['String']['input']>
  companyNumber?: InputMaybe<Scalars['String']['input']>
  companyPhone?: InputMaybe<Scalars['String']['input']>
  companySize?: InputMaybe<CompanySizeEnum>
  companyStreet?: InputMaybe<Scalars['String']['input']>
  companyType?: InputMaybe<CompanyTypeEnum>
  companyZip?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  jobLevel?: InputMaybe<JobLevelEnum>
  lastName?: InputMaybe<Scalars['String']['input']>
  networkingAccepted?: InputMaybe<Scalars['Boolean']['input']>
  omrMatchConsent?: InputMaybe<Scalars['Boolean']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  position?: InputMaybe<Scalars['String']['input']>
  profileImage?: InputMaybe<Scalars['Upload']['input']>
  salutation?: InputMaybe<SalutationTypeEnum>
}

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection'
  /** A list of edges. */
  edges: Array<UserEdge>
  /** A list of nodes. */
  nodes: Array<User>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  totalCount: Scalars['Int']['output']
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<User>
}

export type UserPropertiesPreferences = {
  __typename?: 'UserPropertiesPreferences'
  companyDepartments: Array<CompanyDepartments>
  companyFields: Array<CompanyFields>
  companyFieldsByIndustry: Array<CompanyFields>
  companyIndustries: Array<CompanyIndustries>
  companySizes: Array<CompanySizes>
  companyTypes: Array<CompanyTypes>
  countries: Array<Country>
  id: Scalars['ID']['output']
  jobLevels: Array<JobLevels>
  salutations: Array<Salutations>
}

export type UserPropertiesPreferencesCompanyFieldsByIndustryArgs = {
  id: Scalars['ID']['input']
}

export type Variant = {
  __typename?: 'Variant'
  currency: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  price: Scalars['Float']['output']
  product: Product
}

export type VisitedEvent = {
  __typename?: 'VisitedEvent'
  name: Scalars['String']['output']
  slug: Scalars['ID']['output']
}

export type Volunteer = {
  __typename?: 'Volunteer'
  /** Bewerbungsstatus */
  applicationState?: Maybe<VolunteerApplicationStateEnum>
  /** Ausbilding in der Profession (Crew Professional) */
  apprenticeship?: Maybe<Scalars['Boolean']['output']>
  /** Deine Verfügbarkeiten */
  availability?: Maybe<Array<VolunteerAvailabilityEnum>>
  /** Awareness Work Experience */
  awarenessWorkExperience?: Maybe<Scalars['String']['output']>
  /** Awareness Work Most Important */
  awarenessWorkMostImportant?: Maybe<Scalars['String']['output']>
  /** BIC */
  bic?: Maybe<Scalars['String']['output']>
  /** Billing Data */
  billingDataComplete?: Maybe<Scalars['Boolean']['output']>
  /** Billing Form */
  billingForm?: Maybe<VolunteerBillingFormEnum>
  /** Text Abrechnungsdaten sind eingegangen (myOMR) (from Workforce) */
  billingInfoReceivedMessage?: Maybe<Scalars['String']['output']>
  /** Text Es gibt Probleme bei deiner Abrechnung (myOMR) (from Workforce) */
  billingProblemMessage?: Maybe<Scalars['String']['output']>
  /** Billing State */
  billingState?: Maybe<VolunteerBillingStateEnum>
  /** Geburtsland */
  birthCountry?: Maybe<Scalars['String']['output']>
  /** Geburtsort */
  birthPlace?: Maybe<Scalars['String']['output']>
  /** Geburtsdatum */
  birthdate?: Maybe<Scalars['ISO8601Date']['output']>
  /** Rufname */
  callname?: Maybe<Scalars['String']['output']>
  /** Wie bist du auf uns gekommen? */
  cameFrom?: Maybe<Array<VolunteerCameFromEnum>>
  /** If OMR */
  cameFromOmr?: Maybe<Array<VolunteerFromOmrEnum>>
  /** If Sonstige */
  cameFromOther?: Maybe<Scalars['String']['output']>
  /** If Social Media */
  cameFromSocialMedia?: Maybe<Array<VolunteerFromSocialMediaEnum>>
  /** If Universität / Hochschule */
  cameFromUni?: Maybe<Scalars['String']['output']>
  /** Kinderfreibetrag */
  childrenFreeAmount?: Maybe<Scalars['String']['output']>
  /** Wohnort */
  city?: Maybe<Scalars['String']['output']>
  /** Codewort */
  codeword?: Maybe<Scalars['String']['output']>
  /** Was ist dir wichtig während deines Einsatzes? */
  commitments?: Maybe<Scalars['String']['output']>
  /** Zusatzinformation */
  complement?: Maybe<Scalars['String']['output']>
  /** Konfektionsgröße */
  confectionSize?: Maybe<VolunteerClotheSizesEnum>
  /** Bestätigung AGB Freelancer (Crew Professional) */
  consentFreelanceTerms?: Maybe<Scalars['Boolean']['output']>
  /** Bestätigung Gewerbeschein / Fähigkeiten (Crew Professional) */
  consentTradeLicense?: Maybe<Scalars['Boolean']['output']>
  /** Land */
  country?: Maybe<Scalars['String']['output']>
  /** aktueller Studiengang / Ausbildungsbereich */
  currentStudies?: Maybe<VolunteerCurrentStudiesEnum>
  /** Sonstige Fähigkeiten */
  customSkills?: Maybe<Scalars['String']['output']>
  /** Lebenslauf */
  cvFiles?: Maybe<Array<AirtableAttachment>>
  /** Datenschutzhinweise */
  dataPrivacy?: Maybe<Scalars['String']['output']>
  /** Konfession */
  denomination?: Maybe<VolunteerDenominationEnum>
  /** Führerschein */
  driverLicenses?: Maybe<Array<VolunteerDriversLicenseEnum>>
  /** Führerschein */
  driverLicensesFiles?: Maybe<Array<AirtableAttachment>>
  /** E-Mail-Adresse */
  email?: Maybe<Scalars['String']['output']>
  /** Email Consent */
  emailConsent?: Maybe<Scalars['String']['output']>
  /** Notfallkontakt Name */
  emergencyContactName?: Maybe<Scalars['String']['output']>
  /** Notfallkontakt Nummer */
  emergencyContactPhone?: Maybe<Scalars['String']['output']>
  /** Notfallkontakt Beziehung */
  emergencyContactRelation?: Maybe<Scalars['String']['output']>
  /** Voraussichtlicher Abschluss am */
  expectedGraduationDate?: Maybe<Scalars['ISO8601Date']['output']>
  /** External hire */
  externalHire?: Maybe<Scalars['Boolean']['output']>
  /** Vorname */
  firstName?: Maybe<Scalars['String']['output']>
  /** Angebot Freelancer (Crew Professional) */
  freelanceOfferFiles?: Maybe<Array<AirtableAttachment>>
  /** Geschlecht */
  gender?: Maybe<Scalars['String']['output']>
  /** Behindertenausweis */
  handicapped?: Maybe<Scalars['Boolean']['output']>
  /** Gesundheitszeugnis */
  healthCertificate?: Maybe<Scalars['Boolean']['output']>
  /** Gesundheitszeugnis */
  healthCertificateFiles?: Maybe<Array<AirtableAttachment>>
  /** Krankenkasse */
  healthInsurance?: Maybe<Scalars['String']['output']>
  /** Krankenkasse sonstige */
  healthInsuranceOther?: Maybe<Scalars['String']['output']>
  /** Versicherungstyp */
  healthInsuranceType?: Maybe<VolunteerHealthInsuranceTypeEnum>
  /** IBAN */
  iban?: Maybe<Scalars['String']['output']>
  /** Identifikationsnummer */
  id: Scalars['ID']['output']
  /** Personalausweis / Reisepass */
  idCardPassportFiles?: Maybe<Array<AirtableAttachment>>
  /** Flurförderschein */
  industrialTransportPermitFiles?: Maybe<Array<AirtableAttachment>>
  /** Einwilligung Jobangebote */
  jobOfferConsent?: Maybe<Scalars['String']['output']>
  /** Nachweis Arbeitnehmer */
  jobTitleEmployer?: Maybe<Scalars['String']['output']>
  /** Jobs Crew Professional */
  jobsCrewProfessional?: Maybe<JobsCrewProfessionalEnum>
  /** Fremdsprachen */
  languages?: Maybe<Scalars['String']['output']>
  /** Letzte gesetzliche Krankenkasse */
  lastHealthInsurance?: Maybe<Scalars['String']['output']>
  /** Nachname */
  lastName?: Maybe<Scalars['String']['output']>
  /** Steuerklassenverifikation 1-5 */
  mainJob?: Maybe<Scalars['Boolean']['output']>
  /** Familienstand */
  maritalStatus?: Maybe<VolunteerMaritalStatusEnum>
  /** Marketing Skills */
  marketingSkills?: Maybe<Array<VolunteerMarketingSkillsEnum>>
  /** Staatsangehörigkeit */
  nationality?: Maybe<Scalars['String']['output']>
  /** Einwilligung Emails */
  news?: Maybe<Scalars['String']['output']>
  /** Not Available at */
  notAvailable?: Maybe<Scalars['String']['output']>
  /** Hausnummer */
  number?: Maybe<Scalars['String']['output']>
  otherDocumentsFiles?: Maybe<Array<AirtableAttachment>>
  /** Personenbeförderungsschein */
  passengerTransportLicenseFiles?: Maybe<Array<AirtableAttachment>>
  /** Handynummer */
  phone?: Maybe<Scalars['String']['output']>
  /** Photo Video Skills */
  photoVideoSkills?: Maybe<Array<VolunteerPhotoVideoSkillsEnum>>
  /** Post Wohnort */
  postalCity?: Maybe<Scalars['String']['output']>
  /** Post Zusatzinformation */
  postalComplement?: Maybe<Scalars['String']['output']>
  /** Post Land */
  postalCountry?: Maybe<Scalars['String']['output']>
  /** Post Hausnummer */
  postalNumber?: Maybe<Scalars['String']['output']>
  /** Post Straße */
  postalStreet?: Maybe<Scalars['String']['output']>
  /** Post Postleitzahl */
  postalZip?: Maybe<Scalars['String']['output']>
  /** Bild */
  profilePicture?: Maybe<Array<AirtableAttachment>>
  /** Nachweis Arbeitnehmer (Anstellungsart) */
  proofEmployerJobTitle?: Maybe<Scalars['String']['output']>
  /** Nachweis Arbeitnehmer (Name Arbeitgeber) */
  proofEmployerName?: Maybe<Scalars['String']['output']>
  /** Nachweis Selbstständig */
  proofSelfEmployed?: Maybe<Scalars['String']['output']>
  /** Nachweis Schüler */
  pupilProofFiles?: Maybe<Array<AirtableAttachment>>
  /** Referenzen (Crew Professional) */
  references?: Maybe<Scalars['String']['output']>
  /** Tätigkeiten im Gewerbeschein (Crew Professional) */
  registeredActivitiesInTradeLicense?: Maybe<Scalars['String']['output']>
  /** Lohnvorstellung (Crew Professional) */
  salaryExpectations?: Maybe<Scalars['String']['output']>
  /** Selbstbeschreibung */
  selfDescription?: Maybe<Scalars['String']['output']>
  /** Schichten (from Workforce) */
  shifts?: Maybe<Array<Shift>>
  /** Schuhgröße */
  shoeSize?: Maybe<VolunteerShoeSizesEnum>
  /** Steuerklassenverifikation 6 */
  sideJob?: Maybe<Scalars['Boolean']['output']>
  /** Fähigkeiten und Bescheinigungen */
  skills?: Maybe<Array<VolunteerSkillsEnum>>
  /** Social Media Profil */
  socialMedia?: Maybe<Scalars['String']['output']>
  /** Sozialversicherungsnummer */
  socialSecurityNumber?: Maybe<Scalars['String']['output']>
  /** Straße */
  street?: Maybe<Scalars['String']['output']>
  /** Nachweis Student */
  studentProofFiles?: Maybe<Array<AirtableAttachment>>
  /** Steuerklasse */
  taxClass?: Maybe<VolunteerTaxClassEnum>
  /** Steueridentifikationsnummer */
  taxId?: Maybe<Scalars['String']['output']>
  /** Steuernummer (Crew Professional) */
  taxNumber?: Maybe<Scalars['String']['output']>
  /** Teamleaderbewerbung */
  teamLeader?: Maybe<Scalars['Boolean']['output']>
  /** Teamleaderbewerbung Qualifikation */
  teamLeaderQualification?: Maybe<Scalars['String']['output']>
  /** Testkonto */
  testAccount?: Maybe<Scalars['Boolean']['output']>
  /** Was ist dir wichtig während deines Einsatzes? */
  whatIsImportantForYou?: Maybe<Array<VolunteerWhatIsImportantEnum>>
  /** Arbeitserlaubnis */
  workPermitFiles?: Maybe<Array<AirtableAttachment>>
  /** Berufsstatus bei Beginn der Beschäftigung */
  workStatus?: Maybe<VolunteerWorkStatusEnum>
  /** Zusammenarbeit mit */
  workWith?: Maybe<Scalars['String']['output']>
  /** Bereits bei OMR gearbeitet (Crew Professional) */
  workedBefore?: Maybe<Scalars['String']['output']>
  /** Deine Angebot */
  yourOfferFiles?: Maybe<Scalars['String']['output']>
  /** Postleitzahl */
  zip?: Maybe<Scalars['String']['output']>
}

export enum VolunteerApplicationStateEnum {
  Accepted = 'accepted',
  Applied = 'applied',
  Denied = 'denied',
  Draft = 'draft',
  MissingDocuments = 'missing_documents',
  Timeout = 'timeout',
  Waitlist = 'waitlist',
  Withdrawn = 'withdrawn',
}

/** Attributes for creating new crew volunteer in airtable */
export type VolunteerAttributes = {
  /** Ausbilding in der Profession (Crew Professional) */
  apprenticeship?: InputMaybe<Scalars['Boolean']['input']>
  /** Deine Verfügbarkeiten */
  availability?: InputMaybe<Array<VolunteerAvailabilityEnum>>
  /** Awareness Work Experience */
  awarenessWorkExperience?: InputMaybe<Scalars['String']['input']>
  /** Awareness Work Most Important */
  awarenessWorkMostImportant?: InputMaybe<Scalars['String']['input']>
  /** BIC */
  bic?: InputMaybe<Scalars['String']['input']>
  /** Billing Data */
  billingDataComplete?: InputMaybe<Scalars['Boolean']['input']>
  /** Billing Form */
  billingForm?: InputMaybe<VolunteerBillingFormEnum>
  /** Geburtsland */
  birthCountry?: InputMaybe<Scalars['String']['input']>
  /** Geburtsort */
  birthPlace?: InputMaybe<Scalars['String']['input']>
  /** Rufname */
  callname?: InputMaybe<Scalars['String']['input']>
  /** Wie bist du auf uns gekommen? */
  cameFrom?: InputMaybe<Array<VolunteerCameFromEnum>>
  /** If OMR */
  cameFromOmr?: InputMaybe<Array<VolunteerFromOmrEnum>>
  /** If Sonstige */
  cameFromOther?: InputMaybe<Scalars['String']['input']>
  /** If Social Media */
  cameFromSocialMedia?: InputMaybe<Array<VolunteerFromSocialMediaEnum>>
  /** If Universität / Hochschule */
  cameFromUni?: InputMaybe<Scalars['String']['input']>
  /** Kinderfreibetrag */
  childrenFreeAmount?: InputMaybe<Scalars['String']['input']>
  /** Wohnort */
  city?: InputMaybe<Scalars['String']['input']>
  /** Codewort */
  codeword?: InputMaybe<Scalars['String']['input']>
  /** Was ist dir wichtig während deines Einsatzes? */
  commitments?: InputMaybe<Scalars['String']['input']>
  /** Zusatzinformation */
  complement?: InputMaybe<Scalars['String']['input']>
  /** Konfektionsgröße */
  confectionSize?: InputMaybe<VolunteerClotheSizesEnum>
  /** Bestätigung AGB Freelancer (Crew Professional) */
  consentFreelanceTerms?: InputMaybe<Scalars['Boolean']['input']>
  /** Bestätigung Gewerbeschein / Fähigkeiten (Crew Professional) */
  consentTradeLicense?: InputMaybe<Scalars['Boolean']['input']>
  /** Land */
  country?: InputMaybe<Scalars['String']['input']>
  /** aktueller Studiengang / Ausbildungsbereich */
  currentStudies?: InputMaybe<VolunteerCurrentStudiesEnum>
  /** Sonstige Fähigkeiten */
  customSkills?: InputMaybe<Scalars['String']['input']>
  /** Lebenslauf */
  cvFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Datenschutzhinweise */
  dataPrivacy?: InputMaybe<Scalars['String']['input']>
  /** Konfession */
  denomination?: InputMaybe<VolunteerDenominationEnum>
  /** Führerschein */
  driverLicenses?: InputMaybe<Array<VolunteerDriversLicenseEnum>>
  /** Führerschein */
  driverLicensesFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Email Consent */
  emailConsent?: InputMaybe<Scalars['String']['input']>
  /** Notfallkontakt Name */
  emergencyContactName?: InputMaybe<Scalars['String']['input']>
  /** Notfallkontakt Nummer */
  emergencyContactPhone?: InputMaybe<Scalars['String']['input']>
  /** Notfallkontakt Beziehung */
  emergencyContactRelation?: InputMaybe<Scalars['String']['input']>
  /** Voraussichtlicher Abschluss am */
  expectedGraduationDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>
  /** Angebot Freelancer (Crew Professional) */
  freelanceOfferFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Behindertenausweis */
  handicapped?: InputMaybe<Scalars['Boolean']['input']>
  /** Gesundheitszeugnis */
  healthCertificate?: InputMaybe<Scalars['Boolean']['input']>
  /** Gesundheitszeugnis */
  healthCertificateFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Krankenkasse */
  healthInsurance?: InputMaybe<Scalars['String']['input']>
  /** Krankenkasse sonstige */
  healthInsuranceOther?: InputMaybe<Scalars['String']['input']>
  /** Versicherungstyp */
  healthInsuranceType?: InputMaybe<VolunteerHealthInsuranceTypeEnum>
  /** IBAN */
  iban?: InputMaybe<Scalars['String']['input']>
  /** Personalausweis / Reisepass */
  idCardPassportFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Flurförderschein */
  industrialTransportPermitFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Einwilligung Jobangebote */
  jobOfferConsent?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Arbeitnehmer */
  jobTitleEmployer?: InputMaybe<Scalars['String']['input']>
  /** Jobs Crew Professional */
  jobsCrewProfessional?: InputMaybe<JobsCrewProfessionalEnum>
  /** Fremdsprachen */
  languages?: InputMaybe<Scalars['String']['input']>
  /** Letzte gesetzliche Krankenkasse */
  lastHealthInsurance?: InputMaybe<Scalars['String']['input']>
  /** Steuerklassenverifikation 1-5 */
  mainJob?: InputMaybe<Scalars['Boolean']['input']>
  /** Familienstand */
  maritalStatus?: InputMaybe<VolunteerMaritalStatusEnum>
  /** Marketing Skills */
  marketingSkills?: InputMaybe<Array<VolunteerMarketingSkillsEnum>>
  /** Staatsangehörigkeit */
  nationality?: InputMaybe<Scalars['String']['input']>
  /** Einwilligung Emails */
  news?: InputMaybe<Scalars['String']['input']>
  /** Not Available at */
  notAvailable?: InputMaybe<Scalars['String']['input']>
  /** Hausnummer */
  number?: InputMaybe<Scalars['String']['input']>
  otherDocumentsFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Personenbeförderungsschein */
  passengerTransportLicenseFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Handynummer */
  phone?: InputMaybe<Scalars['String']['input']>
  /** Photo Video Skills */
  photoVideoSkills?: InputMaybe<Array<VolunteerPhotoVideoSkillsEnum>>
  /** Post Wohnort */
  postalCity?: InputMaybe<Scalars['String']['input']>
  /** Post Zusatzinformation */
  postalComplement?: InputMaybe<Scalars['String']['input']>
  /** Post Land */
  postalCountry?: InputMaybe<Scalars['String']['input']>
  /** Post Hausnummer */
  postalNumber?: InputMaybe<Scalars['String']['input']>
  /** Post Straße */
  postalStreet?: InputMaybe<Scalars['String']['input']>
  /** Post Postleitzahl */
  postalZip?: InputMaybe<Scalars['String']['input']>
  /** Bild */
  profilePicture?: InputMaybe<AirtableAttachmentAttributes>
  /** Nachweis Arbeitnehmer (Anstellungsart) */
  proofEmployerJobTitle?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Arbeitnehmer (Name Arbeitgeber) */
  proofEmployerName?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Selbstständig */
  proofSelfEmployed?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Schüler */
  pupilProofFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Referenzen (Creew Professional) */
  references?: InputMaybe<Scalars['String']['input']>
  /** Tätigkeiten im Gewerbeschein (Crew Professional) */
  registeredActivitiesInTradeLicense?: InputMaybe<Scalars['String']['input']>
  /** Lohnvorstellung (Creew Professional) */
  salaryExpectations?: InputMaybe<Scalars['String']['input']>
  /** Selbstbeschreibung */
  selfDescription?: InputMaybe<Scalars['String']['input']>
  /** Schuhgröße */
  shoeSize?: InputMaybe<VolunteerShoeSizesEnum>
  /** Steuerklassenverifikation 6 */
  sideJob?: InputMaybe<Scalars['Boolean']['input']>
  /** Fähigkeiten und Bescheinigungen */
  skills?: InputMaybe<Array<VolunteerSkillsEnum>>
  /** Social Media Profil */
  socialMedia?: InputMaybe<Scalars['String']['input']>
  /** Sozialversicherungsnummer */
  socialSecurityNumber?: InputMaybe<Scalars['String']['input']>
  /** Straße */
  street?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Student */
  studentProofFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Steuerklasse */
  taxClass?: InputMaybe<VolunteerTaxClassEnum>
  /** Steueridentifikationsnummer */
  taxId?: InputMaybe<Scalars['String']['input']>
  /** Steuernummer (Crew Professional) */
  taxNumber?: InputMaybe<Scalars['String']['input']>
  /** Teamleaderbewerbung */
  teamLeader?: InputMaybe<Scalars['Boolean']['input']>
  /** Teamleaderbewerbung Qualifikation */
  teamLeaderQualification?: InputMaybe<Scalars['String']['input']>
  /** Testkonto */
  testAccount?: InputMaybe<Scalars['Boolean']['input']>
  /** Was ist dir wichtig während deines Einsatzes? */
  whatIsImportantForYou?: InputMaybe<Array<VolunteerWhatIsImportantEnum>>
  /** Arbeitserlaubnis */
  workPermitFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Berufsstatus bei Beginn der Beschäftigung */
  workStatus?: InputMaybe<VolunteerWorkStatusEnum>
  /** Zusammenarbeit mit */
  workWith?: InputMaybe<Scalars['String']['input']>
  /** Bereits bei OMR gearbeitet (Crew Professional) */
  workedBefore?: InputMaybe<Scalars['String']['input']>
  /** Nachweis Selbstständig */
  yearlyRevenues?: InputMaybe<Scalars['String']['input']>
  /** Deine Angebot */
  yourOfferFiles?: InputMaybe<AirtableAttachmentAttributes>
  /** Postleitzahl */
  zip?: InputMaybe<Scalars['String']['input']>
}

export enum VolunteerAvailabilityEnum {
  AlwaysTime = 'always_time',
  EarlyShifts = 'early_shifts',
  FollowupPhase = 'followup_phase',
  LateShifts = 'late_shifts',
  NightShifts = 'night_shifts',
  PreparationPhase = 'preparation_phase',
}

export enum VolunteerBillingFormEnum {
  Employee = 'employee',
  Freelance = 'freelance',
}

export enum VolunteerBillingStateEnum {
  BasedataWrong = 'basedata_wrong',
  BillingdataReady = 'billingdata_ready',
  DataMissing = 'data_missing',
  DataReadyForCheck = 'data_ready_for_check',
  MovementdataWrong = 'movementdata_wrong',
  NoBilling = 'no_billing',
  PaidOut = 'paid_out',
  PayoutGranted = 'payout_granted',
}

export enum VolunteerCameFromEnum {
  FriendsAndFamily = 'friends_and_family',
  OmrChannels = 'omr_channels',
  Other = 'other',
  PrintAdvertising = 'print_advertising',
  Socialmedia = 'socialmedia',
  University = 'university',
}

export enum VolunteerClotheSizesEnum {
  Size_2Xl = 'size_2XL',
  Size_3Xl = 'size_3XL',
  Size_4Xl = 'size_4XL',
  SizeL = 'size_L',
  SizeM = 'size_M',
  SizeS = 'size_S',
  SizeXl = 'size_XL',
  SizeXs = 'size_XS',
}

export enum VolunteerCurrentStudiesEnum {
  ComputerScience = 'computer_science',
  DesignCreation = 'design_creation',
  Engineering = 'engineering',
  EventTourismHospitality = 'event_tourism_hospitality',
  Healthcare = 'healthcare',
  HumanResources = 'human_resources',
  LawEconomics = 'law_economics',
  LinguisticsCulturalStudies = 'linguistics_cultural_studies',
  LogisticsTransportation = 'logistics_transportation',
  MarketingCommunication = 'marketing_communication',
  NaturalSciencesResearch = 'natural_sciences_research',
  Other = 'other',
  SocietySocialSciences = 'society_social_sciences',
}

export enum VolunteerDenominationEnum {
  /** Freie Religionsgemeinschaft Alzey */
  FreeReligiousCommunityAlzey = 'free_religious_community_alzey',
  /** Freireligiöse Gemeinde Mainz */
  FreeReligiousCommunityMainz = 'free_religious_community_mainz',
  /** Freireligiöse Gemeinde Offenbach/Mainz */
  FreeReligiousCommunityOffenbachMainz = 'free_religious_community_offenbach_mainz',
  /** Freireligiöse Landesgemeinde Pfalz */
  FreeReligiousRegionalCommunityOfThePalatinate = 'free_religious_regional_community_of_the_palatinate',
  /** Freireligiöse Landesgemeinde Baden */
  FreeReligiousStateCommunityOfBaden = 'free_religious_state_community_of_baden',
  /** Israelitische Kultussteuer der kultusberechtigten Gemeinden */
  IsraeliteCulturalTaxOfTheCommunitiesEntitledToWorship = 'israelite_cultural_tax_of_the_communities_entitled_to_worship',
  /** Israelitische / Jüdische Kultussteuer */
  IsraeliteJewishCulturalTax = 'israelite_jewish_cultural_tax',
  /** Israelitische Religionsgemeinschaft Baden */
  IsraeliteReligiousCommunityOfBaden = 'israelite_religious_community_of_baden',
  /** Israelitische Religionsgemeinschaft Württembergs */
  IsraeliteReligiousCommunityOfWuerttemberg = 'israelite_religious_community_of_wuerttemberg',
  /** Jüdische Kultussteuer */
  JewishCulturalTax = 'jewish_cultural_tax',
  /** keine Konfession / keine steuerlich relevante Konfession */
  NoDenominationNoTaxRelevantDenomination = 'no_denomination_no_tax_relevant_denomination',
  /** Altkatholisch */
  OldCatholic = 'old_catholic',
  /** Evangelisch */
  Protestant = 'protestant',
  /** Römisch-Katholisch */
  RomanCatholic = 'roman_catholic',
}

export enum VolunteerDriversLicenseEnum {
  B = 'b',
  Be = 'be',
  C = 'c',
  Ce = 'ce',
  No = 'no',
  PassengerTransport = 'passenger_transport',
  Trucking = 'trucking',
}

export enum VolunteerFromOmrEnum {
  Festival = 'festival',
  Newsletter = 'newsletter',
  Podcast = 'podcast',
  Website = 'website',
}

export enum VolunteerFromSocialMediaEnum {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Tiktok = 'tiktok',
  Twitch = 'twitch',
}

export enum VolunteerHealthInsuranceTypeEnum {
  Private = 'private',
  Statutory = 'statutory',
  VoluntaryStatutory = 'voluntary_statutory',
}

export enum VolunteerMaritalStatusEnum {
  /** geschieden */
  Divorced = 'divorced',
  /** verheiratet */
  Married = 'married',
  /** dauernd getrennt lebend */
  PermanentlySeparated = 'permanently_separated',
  /** ledig */
  Single = 'single',
  /** verwitwet */
  Widowed = 'widowed',
}

export enum VolunteerMarketingSkillsEnum {
  BrandManagement = 'brand_management',
  Consulting = 'consulting',
  ContentMarketing = 'content_marketing',
  CustomerRelationManagement = 'customer_relation_management',
  DataAnalytics = 'data_analytics',
  ECommerce = 'e_commerce',
  OnlineMarketing = 'online_marketing',
  Other = 'other',
  PublicRelation = 'public_relation',
}

export enum VolunteerPhotoVideoSkillsEnum {
  Cut = 'cut',
  ImageEditing = 'image_editing',
  Other = 'other',
  Photo = 'photo',
  PhotoManagement = 'photo_management',
  Video = 'video',
}

export enum VolunteerShoeSizesEnum {
  Size_34 = 'size_34',
  Size_35 = 'size_35',
  Size_36 = 'size_36',
  Size_37 = 'size_37',
  Size_38 = 'size_38',
  Size_39 = 'size_39',
  Size_40 = 'size_40',
  Size_41 = 'size_41',
  Size_42 = 'size_42',
  Size_43 = 'size_43',
  Size_44 = 'size_44',
  Size_45 = 'size_45',
  Size_46 = 'size_46',
  Size_47 = 'size_47',
  Size_48 = 'size_48',
  Size_49 = 'size_49',
  Size_50 = 'size_50',
  Size_51 = 'size_51',
  Size_52 = 'size_52',
  Size_53 = 'size_53',
}

export enum VolunteerSkillsEnum {
  AwarenessWork = 'awareness_work',
  BarExperience = 'bar_experience',
  Barista = 'barista',
  Catering = 'catering',
  CustomerSupport = 'customer_support',
  Education = 'education',
  EventManagement = 'event_management',
  EventTechnology = 'event_technology',
  HumanResources = 'human_resources',
  Kitchen = 'kitchen',
  Logistics = 'logistics',
  Marketing = 'marketing',
  Other = 'other',
  Phd = 'phd',
  PhotoVideo = 'photo_video',
  ProjectManagement = 'project_management',
  Sales = 'sales',
  SocialMedia = 'social_media',
  Technical = 'technical',
}

export enum VolunteerTaxClassEnum {
  /** Ledig, getrennt, verwitwet. OMR ist dein Hauptarbeitgeber */
  TaxClass_1 = 'tax_class_1',
  /** getrennt lebend oder alleinerziehend */
  TaxClass_2 = 'tax_class_2',
  /** Verheiratet in Kombination mit Steuerklasse 5 */
  TaxClass_3 = 'tax_class_3',
  /** Verheiratet bei gleich hohem Einkommen */
  TaxClass_4 = 'tax_class_4',
  /** Verheiratet in Kombination mit Steuerklasse 3 */
  TaxClass_5 = 'tax_class_5',
  /** Zweit und Nebenjob. OMR ist dein Nebenarbeitgeber */
  TaxClass_6 = 'tax_class_6',
}

export enum VolunteerWhatIsImportantEnum {
  CommunicateWithGuests = 'communicate_with_guests',
  MoveALittle = 'move_a_little',
  PhysicallyActive = 'physically_active',
  RequireBuddySystem = 'require_buddy_system',
  SeeAsMuchAsPossible = 'see_as_much_as_possible',
  SupportBuddySystem = 'support_buddy_system',
  TakeOnLightTasks = 'take_on_light_tasks',
  TakeOnResponsibility = 'take_on_responsibility',
  WorkAsManyHoursAsPossible = 'work_as_many_hours_as_possible',
  WorkLessHours = 'work_less_hours',
}

export enum VolunteerWorkStatusEnum {
  Civilianservice = 'civilianservice',
  Employee = 'employee',
  Homemaker = 'homemaker',
  Minijob = 'minijob',
  Other = 'other',
  ParentalLeave = 'parental_leave',
  PublicServant = 'public_servant',
  Pupil = 'pupil',
  Retiree = 'retiree',
  SchoolDropout = 'school_dropout',
  SchoolEducation = 'school_education',
  SelfEmployed = 'self_employed',
  StudentApplicant = 'student_applicant',
  StudentBachelor = 'student_bachelor',
  StudentMaster = 'student_master',
  Welfare = 'welfare',
}
